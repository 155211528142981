import React, { Component } from 'react';
import { Container, Col, Row, Card } from 'react-bootstrap'
import Testimonials from '../Calls/Testimonials'
import axios from "axios";

const mainContainer = {
  marginTop: '0px',
}

const pushFirstImage = {
  marginTop: '33px',
  maxHeight: '291px',
  maxWidth:  '240px',
  height:    '100px',
  marginLeft: '26px',
}

const rightCharterImages = {
  maxHeight: '340px',
  paddingRight: '0px',
  objectFit: 'cover',
}

const paddingTopTen = {
  paddingTop: '10px',
}

const rightImages = {
  maxWidth: '253px',
  height: 'auto',
  objectFit: 'cover',
}

class YachtCharter extends Component {

  state = {
    page: [],
    isLoading: true,
    errors: null
  };

  getConfig() {
  axios
    .get( process.env.REACT_APP_GEN_API + "pages/32/")
    .then(response => {
      this.setState({
        page: response.data.acf,
      });
      this.componentAfter()

    })
    .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    this.getConfig();

  }

  render() {
    return (
      <Container className="yacht-charter-container" fluid='true' style={mainContainer}>
        <Row className="yacht-charter">
          <Container>
            <Row>
              <Col>
                <h2>Yacht <strong>Charter</strong></h2>
                <div dangerouslySetInnerHTML={{__html: this.state.page.yccta_content}}/>
              </Col>
              <Col className={this.state.page.yccta_quote !== false ? "yacht-charter-right" : "yacht-charter-right--no-testimonial"} >
                <Container>
                  <Row style={ rightCharterImages }>
                    <Col className="yacht-charter-right--col" style={{paddingLeft: '0px', paddingRight: '0px'}}>
                      <Card style={ pushFirstImage }>
                        <Card.Img variant="bottom" src={ this.state.page.yccta_image_1 } alt="image 1" />
                      </Card>
                    </Col>
                    <Col className="yacht-charter-right--col" style={{ marginLeft: '-25px' }}>
                      <Row style={ paddingTopTen }>
                        <Card>
                          <Card.Img variant="middle" style={ rightImages } src={ this.state.page.yccta_image_2 } alt="image 2" />
                        </Card>
                      </Row>
                      <Row style={ paddingTopTen }>
                        <Card>
                          <Card.Img variant="middle" style={ rightImages } src={ this.state.page.yccta_image_3 } alt="image 3" />
                        </Card>
                      </Row>
                      <Row style={ paddingTopTen }>
                        <Card className="yacht-charter-hr">
                          <hr className="yacht-charter-hr-inner" style={ rightImages } />
                        </Card>
                      </Row>
                    </Col>
                  </Row>
                  {this.state.page.yccta_quote !== false ?
                    <Row>
                      <Col>
                        <Card className="quoteCard" style={{maxWidth: '524px'}}>
                          <div className="quoteCard_inner">
                            <Testimonials/>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  : null}
                </Container>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    );
  }

}

export default YachtCharter;
