import React, { Component } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import Intro from '../Components/Content/Intro';
import Slider from '../Components/PageParts/Slider';
import { withRouter } from 'react-router-dom'

const hrShift = {
  transform: 'translateY(331px)',
  height: '123px',
}

class GenericTemplate extends Component {
  constructor() {
    super();
    this.state = {
      theslug: window.location.pathname.slice(7),
      pages: [],
    }
  }

  getConfig() {
    const theApi = `${process.env.REACT_APP_GEN_API}pages?slug=`;
    const theSlug = this.state.theslug;
    const theCall = theApi + theSlug;
    const pagesURL = theCall;
    axios
      .get(pagesURL)
      .then((response) => {
        this.setState({
          pages: response.data,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    this.getConfig();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.getConfig();
    }
  }

  render() {
    const pages = this.state.pages.map((page) => (
      <>
      <Container key={page.id}>
        <Row className="the-content">
          <Col dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
        </Row>
      </Container>
      {page.acf.page_content_repeater ?
        <Container>
            {page.acf.page_content_repeater.map(( page ) => {
                return (
                  <Container key={Math.random()} className="singleLux" style={{ marginBottom: '90px', }} >
                    <Row className="experiances">
                      <Col lg={6} md={12} xs={12} className="experiances--left">
                        <Row>
                          <Col lg={12} md={12} xs={12}>
                            <h2 className="expTitle" style={{textTransform: 'uppercase'}}>{ page.title }</h2>
                            <p dangerouslySetInnerHTML={{__html: page.text}}></p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6} md={12} xs={12} className="experiances--right">
                        <Card>
                          <Card.Img src={page.image} alt={page.title} style={{ objectFit: 'cover', minHeight: '400px', maxHeight: '400px', }} />
                          <Card.ImgOverlay className="overlayHover">
                          <hr className="horixontal d-none d-lg-flex" style={ hrShift } />
                          </Card.ImgOverlay>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                )
              })
            }
        </Container>
      :
        null
      }
      </>
    ));

    const slider = this.state.pages.map((page, index) => {
      if (page.better_featured_image != null) {
        return (
          <Slider
            key={page.id + Math.random()}
            title={page.title.rendered}
            imageSrc={page.better_featured_image.media_details.sizes.large.source_url}
          />
        );
      }
      return (
        <Intro
          key={page.id}
          title={page.title.rendered}
        />
      );
    });

    return (
      <>
        {slider}
        {pages}
      </>
    );
  }
}

export default withRouter(GenericTemplate);
