import React, { Component } from 'react';
import { Col, Container, Row, Accordion } from 'react-bootstrap';
import Summery from './Components/Summary';
import Intro from '../../Content/Intro';
import i18n from '../../i18n';
import TowelsAndLinen from './Extras/TowelsAndLinen';
import ProvisioningServices from './Extras/ProvisioningServices';
import SleepAboad from './Extras/SleepAboad';
import SpecialRequests from './Extras/SpecialRequests';
import AddAnotherRoute from './Extras/AddAnotherRoute';
import Watersports from './Extras/Watersports';
import Drinks from './Extras/Drinks';
import DiningOnBoard from './Extras/DiningOnBoard';
import Guests from './Extras/Guests'

class Step4 extends Component {

  constructor(props) {
      super(props)
      this.state = {
        maxStep: 4,
        guests: 1,
        wineTotal: 0,
      }
      if( this.state.maxStep < 4 ){
        this.state = {
          maxStep: 4,
        }
      }
      this.handleGuestChange = this.handleGuestChange.bind(this);
      this.handleDrinksPrice = this.handleDrinksPrice.bind(this);
    }

  handleGuestChange(number){
    this.setState({
      guests: number
    })
    this.props.updateGuestNumbers(number)
  }

  handleDrinksPrice(number){
    this.setState({
      wineTotal: number
    })
  }

  render() {

    if (this.props.currentStep !== 4) { // Prop: The current step
      return null
    }
    return (
      <Container className="extras">
        <Row>
          <h3 className="step">{i18n.t('step4.span')}</h3>
        </Row>
        <Intro
        title="Select Extras"
        text="Please select extras for your charter so we can make your experience unique."
        removeLine
        />
        <Container>
          <Row>
            <Col className="paymentForm">
              <Accordion className="extras" defaultActiveKey="guests" >
                <Guests handleGuestChange={this.handleGuestChange}/>
                <DiningOnBoard updatePriceForFood={this.props.updatePriceForFood} />
                <Drinks handleDrinksPrice={this.handleDrinksPrice} />
                <Watersports/>
                <AddAnotherRoute valid={this.props.days}/>
                <SpecialRequests/>
                <SleepAboad guests={this.state.guests} valid={this.props.days}/>
                <ProvisioningServices/>
                <TowelsAndLinen next={this.props.next} />
              </Accordion>
            </Col>
            <Summery
              step4
              guests={this.state.guests}
              foodPrice={this.props.foodPrice}
              drinksPrice={this.state.wineTotal}
              startDate={this.props.startDate}
              numberOfDays={this.props.numberOfDays}
              departurePort={this.props.departurePort}
              currentPrice={this.props.currentPrice}
              depositPrice={this.props.depositPrice}
              next={this.props.next}
            />
          </Row>
        </Container>
      </Container>
    );
  }

}

export default Step4;
