import React, { Component } from 'react';
import SubPageHeader from '../Components/PageParts/SubPageHeader';
import Slider from '../Components/PageParts/Slider';
import Intro from '../Components/Content/Intro';
import LuxurayExperiance from '../Components/Content/LuxuryExperiances';
import AreYouInterested from '../Components/Content/AreYouInterested';

class LuxuryExperiencePage extends Component {
  constructor() {
    super();
    this.state = {
      pages: [],
      thePage: [],
      loading: true,
    };
  }

  componentDidMount() {
    const pagesURL = `${process.env.REACT_APP_GEN_API}experiences/`;
    fetch(pagesURL)
      .then(response => response.json())
      .then((response) => {
        this.setState({
          pages: response,
          loading: false
        });
      });

    const thePageURL = `${process.env.REACT_APP_GEN_API}pages/?slug=luxury-experiences`;
    fetch(thePageURL)
      .then(response => response.json())
      .then((response) => {
        this.setState({
          thePage: response,
        });
      });
  }

  render() {
      const header = this.state.thePage.map((page, index) => {
        if (page.better_featured_image) {
          return (
            <Slider
              title="Build your Luxury Experience"
              text="Charter the Algarve in style"
              imageSrc={page.better_featured_image.source_url}
            />
          );
        } else {
          return null;
        }
      });


    const pages = this.state.pages.map((page, index) => {
      if (page.better_featured_image.source_url) {
        return (
          <LuxurayExperiance
            key={index}
            title={page.title.rendered}
            text={page.content.rendered}
            url={page.acf.buttonURL}
            buttonText={page.acf.buttonText}
            imageSrc={page.better_featured_image.source_url}
            alt={page.better_featured_image.alt_text}
            iconOne={page.acf.experience_fontawesome_icon1}
            iconTwo={page.acf.experience_fontawesome_icon2}
          />
        );
      }
      return null;
    });

    if(this.state.loading === true){
      return(
        <>
        </>
      )
    } else {
      return (
        <>
          <SubPageHeader />

          {header}

          <Intro
            title="LUXURY EXPERIENCES"
            text="Immerse yourself in the Sunseeker Experience and let us organise your complete package, from Yachts and Villas to Limousines. Whatever your requirements we are here to help – nothing is too much trouble."
          />
          {pages}
          <AreYouInterested kill_exp={true} />
        </>
      );
    }
  }
}

export default LuxuryExperiencePage;
