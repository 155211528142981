import React, { Component } from 'react';
import Slider from '../Components/PageParts/Slider';
import sliderImageFile from '../images/tmp/contactHeader.png';

const sliderImage = {
  src: sliderImageFile,
  alt: 'this is an image',
};


class NoMatch extends Component {
  render() {
    return (
      <>
        <Slider
          imageSrc={sliderImage.src}
          imageAlt={sliderImage.alt}
          title="Lost at sea"
          text="( 404 error - Please try the main menu to find your page )"
        />
      </>
    );
  }
}

export default NoMatch;
