import React, { Component } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import i18n from '../i18n';

const subHeader = {
  backgroundColor: '#A99B6C',
  color: 'white',
  height: '100%',
  paddingTop: '10px',
  paddingBottom: '10px',
}


class SubMenuHeader extends Component {

  render() {
    return (
      <Container style={ subHeader } fluid>
        <Container>
          <Row className="justify-content-md-center" style={{ margin: '0 auto', height: 'auto', width: '100%', paddingTop: '10px', paddingBottom: '10px'}}>

            <Col sm={12} md={4} lg={2} className="menu-btn horizontalAlign subeMenuHeader_buttons">
              <AnchorLink href='#'>
                <Button disabled variant="reverseWhite">{i18n.t('overview.span')}</Button>
              </AnchorLink>
            </Col>
            {this.props.gallery ?
              <Col sm={12} md={4} lg={2} className="menu-btn horizontalAlign subeMenuHeader_buttons">
                <AnchorLink href='#gallery'>
                  <Button variant="reverseWhite">{i18n.t('gallery.span')}</Button>
                </AnchorLink>
              </Col>
            : null}
            {this.props.brochure ?
            <Col sm={12} md={4} lg={2} className="menu-btn horizontalAlign subeMenuHeader_buttons">
              <AnchorLink href="#brochure">
                <Button variant="reverseWhite">{i18n.t('brochure.span')}</Button>
              </AnchorLink>
            </Col>
            : null}
            {this.props.route ?
            <Col sm={12} md={4} lg={2} className="menu-btn horizontalAlign subeMenuHeader_buttons">
              <AnchorLink href="#dayRoutes">
                <Button variant="reverseWhite">{i18n.t('route.span')}</Button>
              </AnchorLink>
            </Col>
            : null}
            {this.props.bookable ?
              <Col sm={12} md={4} lg={2} className="menu-btn horizontalAlign subeMenuHeader_buttons">
                <NavLink to="/book">
                  <Button variant="reverseWhite" to="/book">{i18n.t('book.span')}</Button>
                </NavLink>
              </Col>
            :
              <Col sm={12} md={4} lg={2} className="menu-btn horizontalAlign subeMenuHeader_buttons">
                <NavLink to="/contact">
                  <Button variant="reverseWhite" to="/contact">{i18n.t('enquire.span')}</Button>
                </NavLink>
              </Col>
            }





          </Row>
        </Container>
      </Container>
    );
  }

}

export default SubMenuHeader;
