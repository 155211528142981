import React, { Component } from 'react';
import {Row, Col, Card} from 'react-bootstrap';
import MoreInformation from './MoreInformation';
import i18n from '../../../../i18n';

class ChefOnboard extends Component {

  constructor(props) {
      super(props);
      this.addActiveClassOne = this.addActiveClassOne.bind(this);
      this.addActiveClassTwo = this.addActiveClassTwo.bind(this);
      this.addActiveClassThree = this.addActiveClassThree.bind(this);
      this.state = {
          activeOne: false,
          activeTwo: false,
          activeThree: false,
      };
  }

  addActiveClassOne() {
      const currentState = this.state.activeOne;
      this.setState({
        activeOne: !currentState,
        activeTwo: false,
        activeThree: false,
      });
      this.props.updatePriceForFood(0)
  };

  addActiveClassTwo() {
      const currentState = this.state.activeTwo;
      this.setState({
        activeOne: false,
        activeTwo: !currentState,
        activeThree: false,
      });
      this.props.updatePriceForFood(0)
  };

  addActiveClassThree() {
      const currentState = this.state.activeThree;
      this.setState({
        activeOne: false,
        activeTwo: false,
        activeThree: !currentState,
      });
      this.props.updatePriceForFood(0)
  };

  render() {
    return (
      <>
      <Row>
        <Row style={{width: '100%', paddingLeft: '27px', paddingTop: '0px'}}>
        <strong>{i18n.t('chef_selection.span')}:</strong>
        </Row>
        <Row style={{width: '100%', paddingTop: '0px'}}>
         <Col md={4}>
          <Card className={ this.state.activeOne ? "menuCard active" : "menuCard"} onClick={this.addActiveClassOne} style={{padding: '30px'}}>
            <h3>Special Cataplana Experience</h3>
            <hr/>
            <span>
            This is a short into to this Chef option.
            </span>
            <p className="price">{i18n.t('enquire.span')}</p>
          </Card>
         </Col>
         <Col md={4}>
           <Card className={ this.state.activeTwo ? "menuCard active" : "menuCard"} onClick={this.addActiveClassTwo} style={{padding: '30px'}}>
             <h3>Special Sunset Barbeque</h3>
             <hr/>
             <span>
             This is a short into to this Chef option.
             </span>
             <p className="price">{i18n.t('enquire.span')}</p>
           </Card>
         </Col>
         <Col md={4}>
           <Card className={ this.state.activeThree ? "menuCard active" : "menuCard"} onClick={this.addActiveClassThree} style={{padding: '30px'}}>
             <h3>Special Bon Bon Michelin Star</h3>
             <hr/>
             <span>
             This is a short into to this Chef option.
             </span>
             <p className="price">{i18n.t('enquire.span')}</p>
           </Card>
         </Col>
        </Row>
      </Row>
      <MoreInformation/>
      </>
    );
  }

}

export default ChefOnboard;
