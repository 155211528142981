import React, { Component } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';

class MenuParent extends Component {

  constructor(){
     super();
      this.state = {
        pages: []
      };
  }

  componentDidMount() {
    let pagesURL = process.env.REACT_APP_MENU_API;
    fetch( pagesURL )
    .then( response => response.json() )
    .then( response => {
      this.setState({
        pages: response
      })
    })
  };

  hideMenu() {
    $('.navbar-collapse').addClass('hide')
    $('.navbar-collapse').removeClass('show')
    $('.navbar-toggler').removeClass('collapsed')
  }

  render() {
    let pages = this.state.pages.map(( page, index ) => {
      if( page.menu_item_parent !== '0' ){
        return (
          <NavLink className="dropdown-item" key={ index } onClick={ () => this.hideMenu() } to={'/about/' + page.slug}>{page.title}</NavLink>
        )
      } else {
        return (
          null
        )
      }
    })

    return (
      <>
        <Nav.Item className="menuItem" >
          <NavDropdown title={this.props.title} id="basic-nav-dropdown" className="ss-menuLink">
            {pages}
          </NavDropdown>
        </Nav.Item>
      </>
    );
  }

}

export default MenuParent;
