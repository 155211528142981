import React, { Component } from 'react';
import { Container, Row, Card, Accordion, Button } from 'react-bootstrap';
import Map from '../Components/Map';
import i18n from '../../../i18n';

class AddAnotherRoute extends Component {

  constructor(){
     super();
      this.state = {
        addonRoutes: []
      };
  }

  addRoute(routeId, routeName){

  }

  render() {
    if(this.props.valid > 1){
      return (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="3">
              {i18n.t('add_another_route.span')}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <Container>
                <Row style={{paddingTop: '0px'}}>
                  <Map style={{width: '50%'}} noButton={true} />
                </Row>
                <Row style={{ paddingBottom: '6px' }}>
                  <Accordion.Toggle as={Button} variant="primary" eventKey="4">{i18n.t('next_section.span')}</Accordion.Toggle>
                </Row>
              </Container>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    } else {
      return null
    }

  }

}

export default AddAnotherRoute;
