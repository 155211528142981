import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import i18n from '../i18n';

import imageFile from '../../images/tmp/hero.png';
import videoFile from '../../images/video/LumaSmall.mp4';


const video = {
  src: videoFile,
  type: 'video/mp4',
}

const image = {
  src: imageFile,
  alt: 'Alt text for hero',
}

const imageStyle = {
  borderRadius: '0px',
  height: 'auto',
}

const heroOverayStyle = {
  transform: 'translateY(27px)',
}


class HomeHero extends Component {

  render() {
    return (
      <Card sm={12} fluid="true" className="bg-dark text-white heroStyle" style={{ backgroundColor: 'black', backgroundImage:  image.src }}>
        <video className="homeVideo" playsInline autoPlay muted loop style={ imageStyle }>
          <source src={ video.src } type="video/mp4" style={ imageStyle } />
        </video>
        <Card.ImgOverlay className="heroOverlay" style={ heroOverayStyle }>
          <div className="heroOverlay_inner">
          <Card.Title className="heroOverlay_title">Build your luxury experience</Card.Title>
          <Card.Text className="heroOverlay_text">
            Charter the Algarve in style
          </Card.Text>
          <NavLink to='/book' className="btn a-primary">{i18n.t('book_experience.span')}</NavLink>
          </div>
        </Card.ImgOverlay>
      </Card>
    );
  }
}

export default HomeHero;
