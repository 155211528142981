import React, { Component } from 'react';
import { Carousel, Container, Row, Col, } from 'react-bootstrap';
import i18n from '../i18n';
import axios from 'axios';

class Itineries extends Component {

  constructor(){
     super();
      this.state = {
        item: []
      };
  }

  getConfig() {
    var theApi = process.env.REACT_APP_GEN_API + 'routes';
    let itemURL = theApi;
    axios
      .get(itemURL)
      .then(response => {
        this.setState({
          item: response.data
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    this.getConfig();
  }

  render() {
    const items = this.state.item.map((item) => (
      <>
        <Container key={Math.random()} className="singleLux" style={{ marginBottom: '90px', }} >
          <Row className="experiances">
            <Col lg={6} md={12} xs={12} className="experiances--left">
              <Row>
                <Col lg={9} md={12} xs={12}>
                  <h2 className="expTitle">{ item.title.rendered }</h2>
                  <p dangerouslySetInnerHTML={{__html: item.content.rendered}}></p>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} xs={12} className="experiances--right">
              <Carousel fade>
                {item.acf.image_repeater.map(( image ) => {
                  return(
                    <Carousel.Item>
                      <img
                        src={image.image}
                        alt="slide"
                        style={{ objectFit: 'cover', objectPosition: 'center', minHeight: '250px', height: 'auto', width: '100%' }}
                      />
                    </Carousel.Item>
                  )
                })}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </>
    ));

    return (
      <Container>
        <Row style={{ marginTop: "30px" }} className="the-experiences">
          <Col>
            <h1>{i18n.t('itineraries.span')}</h1>
          </Col>
        </Row>
        <Row>
          {items}
        </Row>
      </Container>
    );
  }
}

export default Itineries;
