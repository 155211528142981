import React, { Component } from 'react';
import { Button, Col, Card } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import i18n from '../../../i18n';

const summary = {
  textAlign: 'center',
}

const devider = {
  width: '80%',
  height: '1px',
  backgroundColor: '#cdcdcd',
}

class Summery extends Component {

  render() {
    if(this.props.step4){
      if(this.props.guests || this.props.foodPrice || this.props.drinksPrice ){
        return (
          <>
            <Col xs lg="4" className="summaryFrom">
              <Card className="summaryCard" style={summary}>
                <h2>Extras Summary</h2>
                <hr/>
                <strong>{this.props.guests ? "Guests:" : null }</strong>
                <p>{this.props.guests}</p>
                <strong>{this.props.foodPrice ? "Dining Cost:" : null }</strong>
                <p>{this.props.foodPrice ? "€"+this.props.foodPrice : null}</p>
                <strong>{this.props.drinksPrice ? "Bar Cost:" : null}</strong>
                <p>{this.props.drinksPrice ? "€"+this.props.drinksPrice : null}</p>
                <hr style={devider}/>
                <strong>{this.props.foodPrice ? "Price of extras:" : null}</strong>
                <p className="finailPrice">{this.props.foodPrice ? "€"+(this.props.foodPrice + this.props.drinksPrice) : null}</p>
              </Card>
              <Button style={{ marginTop: '30px',}} onClick={this.props.next}>{i18n.t('go_to_checkout.span')}</Button>
            </Col>
          </>
        );
      } else { return null }
    } else {
      return (
        <>
          <Col xs lg="4" className="summaryFrom">
            <Card className="summaryCard" style={summary}>
              <h2>Charter Summary</h2>
              <hr/>
              <strong>Dates:</strong>
              <p>{(this.props.startDate || (this.props.startDate === "Invalid date") ? this.props.startDate : 'no dates selected ') + (this.props.numberOfDays > 1 ? '(for ' + this.props.numberOfDays + ' days)' : ' ')}</p>
              <strong>Departure City/Harbour:</strong>
              <p>{this.props.departurePort ? this.props.departurePort : 'no route selected'}</p>
              <strong>Total Price:</strong>
              <p><NumberFormat value={this.props.currentPrice ? this.props.currentPrice.toFixed(2) : '0'} displayType={'text'} thousandSeparator={true} prefix={'€'}/></p>
              <hr style={devider}/>
              <strong>Despoit Payable:</strong>
              <p className="finailPrice"><NumberFormat value={ this.props.depositPrice ? this.props.depositPrice.toFixed(2) : '0'} displayType={'text'} thousandSeparator={true} prefix={'€'}/></p>
            </Card>
          </Col>
        </>
      );
    }
  }
}

export default Summery;
