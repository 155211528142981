import React, { Component } from 'react';
import Slider from '../Components/PageParts/Slider';
import SliderLoading from '../Components/PageParts/SliderLoading';
import OurYachtSingle from '../Components/Content/Singles/OurYachtSingle';
import sliderImageFile from '../images/tmp/ourYachtsHeader.png';

const sliderImage = {
  src: sliderImageFile,
  alt: 'this is an image',
};

class OurYactsPage extends Component {
  constructor() {
    super();
    this.state = {
      pages: [],
      featuredYacht: [],
      loading: true,
    };
  }

  componentDidMount() {
    const pagesURL = `${process.env.REACT_APP_GEN_API}yachts/`;
    fetch(pagesURL)
      .then(response => response.json())
      .then((response) => {
        this.setState({
          pages: response,
        });
      });
    const featURL = `${process.env.REACT_APP_GEN_API}pages?slug=our-yachts`;
    fetch(featURL)
      .then(response => response.json())
      .then((response) => {
        this.setState({
          featuredYacht: response,
          loading: false
        });
      });
  }

  render() {

    const featYachtResult = this.state.featuredYacht;
    const slider = featYachtResult.map((page, index) => (

      <Slider
        loaded={this.state.loading}
        key={page.id}
        imageSrc={ page.better_featured_image.source_url }
        imageAlt={ sliderImage.alt }
        title="Our Yachts"
      />
    ));

    const theResult = this.state.pages;
    const pages = theResult.map((page) => {
      if(page.acf.yacht_bookable === true){
        if (page.title.rendered.length <= 20) {
          return (
            <OurYachtSingle
              key={page.id}
              title={page.title.rendered}
              details={`${page.acf.yachtoverview_intro.substring(0, 220)}..`}
              price={page.acf.yacht_price}
              imageSrc={page.acf.image}
              imageAlt={page.acf.image}
              downloadLink={page.acf.brochure_link}
              pageLink={page.slug}
              routeLink={page.acf.routeLink}
              galleryLink={page.acf.image_slider}
            />
          );
        } return null;
      } return null;
    });

    const pagesUnbookable = theResult.map((page) => {
      if(page.acf.yacht_bookable !== true){
        if (page.title.rendered.length <= 20) {
          return (
            <OurYachtSingle
              key={page.id}
              title={page.title.rendered}
              details={`${page.acf.yachtoverview_intro.substring(0, 220)}..`}
              price={page.acf.yacht_price}
              imageSrc={page.acf.image}
              imageAlt={page.acf.image}
              downloadLink={page.acf.brochure_link}
              pageLink={page.slug}
              routeLink={page.acf.routeLink}
              galleryLink={page.acf.image_slider}
            />
          );
        } return null;
      } return null;
    });

    if(this.state.loading === true){
      return(
        <>
        </>
      )
    } else {
      return (
        <>
          { this.state.loading
            ? <SliderLoading/>
            : slider}
          {pages}
          {pagesUnbookable}
        </>
      );
    }
  }
}

export default OurYactsPage;
