import React, { Component } from 'react';
import { Container, Row  } from 'react-bootstrap';
import ExperienceIcon from './ExperiencesIcon'
import TheExperiences from './TheExperiences'

class AreYouInterested extends Component {

  constructor(){
   super();
    this.state = {
      pages: []
    };
  }

  componentDidMount() {
    let pagesURL = process.env.REACT_APP_GEN_API + 'experiences/?per_page=5';
    fetch( pagesURL )
    .then( response => response.json() )
    .then( response => {
      this.setState({
        pages: response
      })
    })
  };

  render() {
    let pages = this.state.pages.map(( page, index ) => {
      return (
        <ExperienceIcon
          key={ index }
          title={ page.title.rendered }
          icon={ page.acf.experience_icon }
        />
      )
    })

    return (
      <Container className="are_you_interested">
        <Row style={{ marginTop: '30px' }} className="justify-content-center">
          <h1>Are you <strong>Interested?</strong></h1>
          <p>
            What ever your requirements, nothing is too much trouble. Get in touch with us to discuss how we can make this an immersive, memorable experience.
          </p>
        </Row>
        <Row>
          <Row className="justify-content-center" style={{ width: '100%' }}>
            {pages}
          </Row>
        </Row>
        <TheExperiences kill_exp={this.props.kill_exp}/>
      </Container>
    );
  }

}

export default AreYouInterested;
