import React, { Component } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const cardOverlay = {
  textAlign: 'center',
  bottom: '30px',
}

const adjustOverlayHeader = {
  top: '-10%',
}

class BlogPostPreview extends Component {

  render() {
    return (
      <Container>
        <Card className="feat-yachts even-yacht text-white">
          <Card.Img src={ this.props.bgImage } alt={ this.props.alt }/>
          <Card.ImgOverlay style={cardOverlay}>
            <Card.Title>{ this.props.title }</Card.Title>
          </Card.ImgOverlay>
          <Card.ImgOverlay className="overlayHover" style={cardOverlay}>
            <Card className="overlayHoverCard">
              <Row>
                <Col>
                  <div style={adjustOverlayHeader} className="overlayHoverCardTitle">{ this.props.title }</div>
                  <div className="overlayHoverCardText">{ this.props.text }</div>
                </Col>
              </Row>
            </Card>
          </Card.ImgOverlay>
        </Card>
      </Container>
    );
  }

}

export default BlogPostPreview;
