import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Intro from '../../Content/Intro';
import SingleYacht from './Components/SingleYacht'
import i18n from '../../i18n';

class Step1 extends Component {

  constructor(){
     super();
      this.state = {
        pages: []
      };
  }

  componentDidMount() {
    let pagesURL = process.env.REACT_APP_GEN_API + 'yachts/';
    fetch( pagesURL )
    .then( response => response.json() )
    .then( response => {
      this.setState({
        pages: response
      })
    })
  }

  render() {

    let pages = this.state.pages.map(( page, index ) => {
        if( page.acf.yacht_bookable === true ){
          return (
              <Col key={ page.id } xs={12} md={6} style={{marginBottom: "30px",}} >
                <SingleYacht
                  sendYacht={this.props.sendYacht}
                  next={ this.props.next }
                  key={ index }
                  id={ page.id }
                  image={ page.acf.image }
                  imageAlt={ page.title.rendered }
                  title={ page.title.rendered }
                  subTitle={ page.acf.sub_title }
                  text={ page.acf.excerpt_text }
                  price={ page.acf.yacht_price_day }
                  priceTwo={ page.acf.yacht_price_day_2 }
                  priceThree={ page.acf.yacht_price_day_3 }
                  priceFour={ page.acf.yacht_price_day_4 }
                  priceFive={ page.acf.yacht_price_day_5 }
                  priceSubsequent={ page.acf.yacht_price_day_subsequent }
                  priceWeekly={ page.acf.yacht_price_weekly }
                  value={ page.id }
                />
              </Col>
          )
        }
        return null;
    })

    if (this.props.currentStep !== 1) { // Prop: The current step
      return null
    }
    return (
      <Container>
        <Row>
          <h3 className="step">{i18n.t('step1.span')}</h3>
        </Row>
        <Intro
        title="Select Yacht"
        text="Please select your preferred yacht"
        removeLine
        />
        <Row className="justify-content-md-center">
          {pages}
        </Row>
      </Container>

    );
  }

}

export default Step1;
