import React, { Component } from 'react';
import {Button, Col} from 'react-bootstrap';

const expIconCol = {
  width: '70px',
  maxWidth: '70px',
  marginRight: '20px',
  float: 'left',
  height: '81px',
}

const expIcon = {
  width: '70px',
  minWidth: '70px',
  maxWidth: '70px',
  height: '70px',
  paddingTop: '5px',
}

const addMarginBottom = {
  marginBottom: '60px',
}

const expTitleCol = {
  maxWidth: '150px',
  float: 'left',
  position: 'relative',
  maxHeight: '70px',
  minHeight: '70px',
  height: '70px',
  verticleAlign: 'middle',
  display: 'inline-table',
}

const interestText = {
  color: '#000000',
  fontFamily: "Futura PT - Book",
  fontSize: '24px',
  fontWeight: '300',
  letterSpacing: '4.67px',
  lineHeight: '23px',
  textTransform: 'uppercase',
  verticalAlign: 'middle',
  display: 'table-cell',
}

const centerit = {
  width: 'auto',
  margin: '0 auto',
  height: '100%',
  display: 'inline-block',
  position: 'relative',
  left: '9%',
}

class ExperiencesIcon extends Component {

  render() {
    var str = this.props.title;
    var lastIndex = str.lastIndexOf(" ");
    var firstPart = str.substring(0, lastIndex);
    return (
      <>
        <Col cs={12} md={4} style={addMarginBottom}>
          <div style={centerit}>
            <Col style={expIconCol}>
              <Button style={expIcon} variant="outline">
                <img src={this.props.icon} alt={this.props.title} />
              </Button>
            </Col>
            <Col style={expTitleCol}>
              <h2 style={ interestText } className="secondBold">{ firstPart }<strong> {this.props.title.split(" ").pop()}</strong></h2>
            </Col>
          </div>
        </Col>
      </>
    );
  }

}

export default ExperiencesIcon;
