import React, { Component } from 'react';
import { Container, Row, Col, Card, Accordion, Button } from 'react-bootstrap';
import i18n from '../../../i18n';
import WineList from '../Components/Extras/WineList';
import WineBasket from '../Components/Extras/WineBasket';

class Drinks extends Component {

  constructor(props) {
      super(props)
      this.state = {
        bar: [],
        wineTotal: 0,
      }
      this.addToBar = this.addToBar.bind(this)
    }

  addToBar(id, name, price, quantity, totalQuantity){
    if( quantity === null ){
      quantity = 1
    }
    const obj = {'key': id, 'name': name, 'price': price, 'quantity': quantity, 'totalQuantity': totalQuantity};

    /* eslint-disable no-unused-vars */
    /* eslint-disable array-callback-return */

      const header = this.state.bar.map((drink, index) => {
        if( obj.key === drink.key || drink.quantity === 0 ){
          return (
            this.state.bar.splice(index, 1)
          );
        }
      });

    /* eslint-enable no-unused-vars */
    /* eslint-enable array-callback-return */

    var joined = this.state.bar.concat(obj);
    this.setState({
      bar: joined,
    }, () => {
      var gettingTotal = this.state.bar.reduce((a,b) => a + b.totalQuantity, 0 )
      this.setState({
          wineTotal: gettingTotal,
        }, () => {
            this.props.handleDrinksPrice(gettingTotal)
      });
    });
  }


  render() {
    return (
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            {i18n.t('drinks.span')}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <Container>
            <Row style={{paddingTop: '0px'}}>
              <Row style={{paddingLeft: '27px', paddingTop: '0px'}}>
                <strong>Drinks your charter pack already includes:</strong>
              </Row>
              <Row style={{width: '100%'}}>
                <Col><p className="preTick">Glass of champagne on arrival</p></Col>
                <Col><p className="preTick">White wine and Rose one bottle per person</p></Col>
                <Col><p className="preTick">Soft drinks unlimited</p></Col>
              </Row>
            </Row>
            <hr/>
              <Row style={{paddingTop: '0px'}}>
                <Col className="wineList">
                  <Row style={{ paddingTop: '0px'}}>
                    <h3><strong>Wines</strong></h3>
                    <hr/>
                  </Row>
                  <Row style={{width: '100%'}}>
                    <ul>
                      <WineList key={1} pushArray={this.addToBar} wineId={1} wineName="Donna Maria Rosé" winePrice={18} />
                      <WineList key={2} pushArray={this.addToBar} wineId={2} wineName="Paxá Viognier" winePrice={25} />
                      <WineList key={3} pushArray={this.addToBar} wineId={3} wineName="Guru Branco" winePrice={65} />
                      <WineList key={4} pushArray={this.addToBar} wineId={4} wineName="Casal Sta Maria Pinot Noir" winePrice={55} />
                      <WineList key={5} pushArray={this.addToBar} wineId={5} wineName="Boa Vista Reserva	Tinto" winePrice={80} />
                      <WineList key={6} pushArray={this.addToBar} wineId={6} wineName="Pintas Tinto" winePrice={15} />
                    </ul>
                  </Row>
                </Col>
                <Col className="wineList">
                  <Row style={{paddingTop: '0px'}}>
                    <Row style={{paddingTop: '0px'}}>
                      <h3><strong>Champagne</strong></h3>
                      <hr/>
                    </Row>
                    <Row style={{width: '100%'}}>
                      <ul>
                        <WineList key={7} pushArray={this.addToBar} wineId={7} wineName="Monopole Heidsieck & Co Brut" winePrice={55} />
                        <WineList key={8} pushArray={this.addToBar} wineId={8} wineName="Pommery Brut Apanage" winePrice={85} />
                        <WineList key={9} pushArray={this.addToBar} wineId={9} wineName="Billecart Salmon Rosé" winePrice={95} />
                      </ul>
                    </Row>
                  </Row>
                </Col>
              <WineBasket inBasket={this.state.bar} />
              </Row>
              <Row style={{ paddingBottom: '6px' }}>
                <Accordion.Toggle as={Button} variant="primary" eventKey="2">{i18n.t('next_section.span')}</Accordion.Toggle>
              </Row>
            </Container>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }

}

export default Drinks;
