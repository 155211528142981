import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Intro from '../Components/Content/Intro';
import BlogPostPreview from '../Components/Content/Singles/BlogPostPreview';

import featImageTwoImg from '../images/tmp/blogImg1.png';
import featImageOneImg from '../images/tmp/blogImg2.png';

const imgTwo = {
  src: featImageTwoImg,
  alt: 'Yacht Two',
};
const imgOne = {
  src: featImageOneImg,
  alt: 'Yacht Two',
};

const hrStyle = {
  height: '5px',
  borderTop: '0px',
  backgroundColor: '#f1f1f1',
  marginLeft: '15px',
  marginRight: '15px',
};


class LatestNews extends Component {
  render() {
    return (
      <>
        <Intro
          title="INTERNATIONAL CHARTERS"
          text="Welcome ipsum dolor sit amet, consectetur adipiscing elit. Donec  ut erat pellentesque metus condimentum eleifend eget sed nibh. Aenean libero tellus, convallis ut leo et, consectetur vestibulum nisl. Phasellus gravida vel ex quis semper. Integer pellentesque sapien id scelerisque. Lorem ipsum dolor sit amet,"
        />

        <Container>
          <Row>
            <Col>
              <BlogPostPreview
                title="Blog post one"
                text="Welcome ipsum dolor sit amet, consectetur adipiscing elit. Donec  ut erat pellentesque metus condimentum eleifend eget sed nibh. Aenean libero tellus, convallis ut leo et, consectetur vestibulum nisl."
                bgImage={imgOne.src}
                alt={imgOne.alt}
              />
              <hr style={hrStyle} />
            </Col>
            <Col>
              <hr style={hrStyle} />
              <BlogPostPreview
                title="Blog Two Post"
                text="Welcome ipsum dolor sit amet, consectetur adipiscing elit. Donec  ut erat pellentesque metus condimentum eleifend eget sed nibh. Aenean libero tellus, convallis ut leo et, consectetur vestibulum nisl."
                bgImage={imgTwo.src}
                alt={imgTwo.alt}
              />
            </Col>
          </Row>
        </Container>


      </>
    );
  }
}

export default LatestNews;
