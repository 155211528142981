import React, { Component } from 'react';
import { Container, Col, Row, Card } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import i18n from '../i18n';

const cardOverlay = {
  textAlign: 'center',
  bottom: '30px',
}

class OurYachts extends Component {

  constructor(){
     super();
      this.state = {
        featYacht: []
      };
  }

  componentDidMount() {
    let pagesURL = process.env.REACT_APP_GEN_API + 'yachts/?per_page=3';
    fetch( pagesURL )
    .then( response => response.json() )
    .then( response => {
      this.setState({
        featYacht: response
      })
    })
  };

  render() {

    let featYachtBookable = this.state.featYacht.map(( featYacht ) => {
      if( featYacht.acf.yacht_bookable === true ){
        if( featYacht.acf.is_featured_on_homepage === true ){
          return (
            < div key={featYacht.id} >
              <Card key={featYacht.id} className="feat-yachts featured-yacht text-white" style={{ maxHeight: '569px' }} >
                <Card.Img src={ featYacht.better_featured_image !== null ? featYacht.better_featured_image.source_url : featYacht.acf.image } alt={ featYacht.better_featured_image } style={{ objectFit: 'cover' }} />
                <Card.ImgOverlay style={cardOverlay}>
                  <Card.Title>{featYacht.title.rendered}</Card.Title>
                </Card.ImgOverlay>
                <Card.ImgOverlay className="overlayHover" style={cardOverlay}>
                  <Card className="overlayHoverCard">
                    <Row>
                      <Col>
                        <div className="overlayHoverCardTitle">{featYacht.title.rendered}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <NavLink to={'/our-yachts/' + featYacht.slug} className="btn a-primary">{i18n.t('more_details.span')}</NavLink>
                      </Col>
                    </Row>
                  </Card>
                </Card.ImgOverlay>
              </Card>
            </div>
          )
        } else {
          return (
            null
          )
        }
      } else {
        return null;
      }
    })

    let featYacht = this.state.featYacht.map(( featYacht ) => {
      if( featYacht.acf.yacht_bookable !== true ){
        if( featYacht.acf.is_featured_on_homepage === true ){
          return (
            < div key={featYacht.id} >
              <Card key={featYacht.id} className="feat-yachts featured-yacht text-white" style={{ maxHeight: '569px' }} >
                <Card.Img src={ featYacht.better_featured_image !== null ? featYacht.better_featured_image.source_url : featYacht.acf.image } alt={ featYacht.better_featured_image } style={{ objectFit: 'cover' }} />
                <Card.ImgOverlay style={cardOverlay}>
                  <Card.Title>{featYacht.title.rendered}</Card.Title>
                </Card.ImgOverlay>
                <Card.ImgOverlay className="overlayHover" style={cardOverlay}>
                  <Card className="overlayHoverCard">
                    <Row>
                      <Col>
                        <div className="overlayHoverCardTitle">{featYacht.title.rendered}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <NavLink to={'/our-yachts/' + featYacht.slug} className="btn a-primary">{i18n.t('more_details.span')}</NavLink>
                      </Col>
                    </Row>
                  </Card>
                </Card.ImgOverlay>
              </Card>
            </div>
          )
        } else {
          return (
            null
          )
        }
      } else {
        return null;
      }
    })

    let otherYachts = this.state.featYacht.map(( featYacht, index ) => {
      if( featYacht.acf.is_featured_on_homepage !== true ){
        if( this.state.featYacht.length >= 3 ){
          return (
            <Col xs={12} md={6} key={featYacht.id}>
              <Card className="feat-yachts even-yacht text-white " style={{ minHeight: '406px', maxHeight: '406px', overflow: 'hidden' }} >
                <Card.Img src={ featYacht.better_featured_image.source_url } style={{ minHeight: '406px', maxHeight: '406px', objectFit: 'cover' }} alt={ featYacht.better_featured_image.media_details.image_meta.title }/>
                <Card.ImgOverlay style={cardOverlay}>
                  <Card.Title>{featYacht.title.rendered}</Card.Title>
                </Card.ImgOverlay>
                <Card.ImgOverlay className="overlayHover" style={cardOverlay}>
                  <Card className="overlayHoverCard">
                    <Row>
                      <Col>
                        <div className="overlayHoverCardTitle">{featYacht.title.rendered}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <NavLink to={'/our-yachts/' + featYacht.slug} className="btn a-primary">{i18n.t('more_details.span')}</NavLink>
                      </Col>
                    </Row>
                  </Card>
                </Card.ImgOverlay>
              </Card>
            </Col>
          )
        } else {
          return (
            null
          )
        }
      } else {
        return (
          null
        )
      }

  })

    return (
      <Container>
        <Row className="our-yachts">
          <Col>
            <h2>our <strong>yachts</strong></h2>
          </Col>
        </Row>
        <Row>
          <Col>
            {featYachtBookable}
            {featYacht}
          </Col>
        </Row>
        <Row className="secondary_yachts">
          {otherYachts}
        </Row>
      </Container>
    );
  }
}

export default OurYachts;
