import React, { Component } from 'react';
import { Container, Col, Row, Card, Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import i18n from '../../i18n';

const yachtRight = {
  maxWidth: "446px",
}

const yachtLeft = {
  borderRadius: "0px",
  maxWidth: "720px",
  marginRight: "30px",
}

const buttonHolder = {
  width: '60px',
  left: '92%',
  top: '28px',
}



class OurYachtSingle extends Component {

  render() {
    return (
      <Container style={{ marginTop: '60px' }} className="OurYachtSingle">
        <Row>
          {this.props.imageSrc ?
          <Col xs={12} md={6}>
            <Card style={yachtLeft} className="bg-dark text-white">
            <Card.Img style={yachtLeft} src={this.props.imageSrc} alt={this.props.imageAlt} />
            <Card.ImgOverlay style={buttonHolder}>
              {this.props.downloadLink ?
                <Button onClick={()=>this.props.downloadLink} variant="mini" data-ico="&#xf019;"></Button>
              : null }
              {this.props.routeLink ?
                <Button onClick={()=>this.props.routeLink} variant="mini" data-ico="&#xf14e;"></Button>
              : null }
              {this.props.galleryLink ?
                <Button onClick={()=>this.props.galleryLink} variant="mini" data-ico="&#xf03e;"></Button>
              : null }
            </Card.ImgOverlay>
            </Card>
          </Col>
          : null }

          <Col xs={12} md={!this.props.imageSrc ? 12 : 6} style={this.props.imageSrc ? yachtRight : null}>
            <h2 className="title">{this.props.title.split(" ")[0]}<strong> {this.props.title.split(" ")[1]}</strong></h2>
            <p className="details">{this.props.details}</p>
            {this.props.price ?
              <span className="from">{i18n.t('from.span')}:</span>
            : null}
              <br/>
            {this.props.price ?
              <span className="price">€{this.props.price} <sub>/ {i18n.t('day.span')}</sub></span>
            : null}
              <br/>
              <br/>
            <NavLink to={'our-yachts/' + this.props.pageLink} className="btn a-primary">{i18n.t('more_details.span')}</NavLink>
          </Col>
        </Row>
      </Container>
    );
  }

}

export default OurYachtSingle;
