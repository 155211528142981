import React, { Component } from 'react';
import { Container, Row, Col, Form, Card, Accordion, Button } from 'react-bootstrap';
import i18n from '../../../i18n';

class SleepAboad extends Component {

  constructor() {
    super()
    this.state = {
      sleepingAboud: 0,
    }
  }

  handleChange() {
    if (this.refs.nights) {
      this.setState({
        sleepingAboud: this.refs.nights.value,
      })

    }
  }



  render() {
    if(this.props.valid > 1){
      return (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="6">
              {i18n.t('sleep_aboad.span')}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              <Container>
                <Row style={{paddingTop: '0px'}}>
                  <Col style={{ paddingLeft: '0px' }} >
                    <Form.Label>how many nights</Form.Label>
                    <Form.Control as="select" ref="nights" value={this.state.sleepingAboud} onChange={() => this.handleChange()} >
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>5+</option>
                    </Form.Control>
                  </Col>
                  { this.state.sleepingAboud > 0 ?
                    <Col>
                      <Form.Label>how many people</Form.Label>
                      <Form.Control as="select">
                        <option>0</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>5+</option>
                      </Form.Control>
                    </Col>
                  :
                  null }
                </Row>
                <Row style={{ paddingBottom: '6px' }}>
                  <Accordion.Toggle as={Button} variant="primary" eventKey="7">{i18n.t('next_section.span')}</Accordion.Toggle>
                </Row>
              </Container>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    } else {
      return null;
    }

  }

}

export default SleepAboad;
