import React, { Component } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap'
import PhoneNumber from '../Calls/PhoneNumber'
import Email from '../Calls/Email'
import i18n from '../i18n'
import $ from 'jquery'

const introText = {
  textAlign: "center",
}

const introTitle = {
  width: '100%',
}

const thankYou = {
  width: '100%',
  height: '447px',
  textAlign: 'center',
  backgroundColor: 'rgb(242, 242, 242)',
  paddingTop: '30px',
}

class ContactForm extends Component {

  constructor() {
      super();
      this.state = {
        name: '',
        phone: '',
        email: '',
        message: '',
        mailSent: false,
        error: null,
        hidden: false,
      }
      this.handleSubmit = this.handleSubmit.bind(this);
    }


  handleSubmit(event) {
    event.preventDefault();

    const theDate = this.state;

    $.ajax({
        data: theDate,
        type: 'POST',
        url: process.env.REACT_APP_API_FORM_PATH,
        success: function(data) {
        },
        error: function(xhr, status, err) {
        }
      })
      this.setState({
        mailSent: true,
      })
  }

  handleMailchimp(event) {
    const theDate = this.state;
    console.log(theDate);
    $.ajax({
        data: theDate,
        type: 'POST',
        url: process.env.REACT_APP_API_MAILCHIMP_PATH,
        success: function(data) {
        },
        error: function(xhr, status, err) {
        }
      })
      this.setState({
        hidden: true,
      })
  }

  render() {
    if(this.state.hidden !== true){
      return (
        <Container className="contact-form_container">
          <Row>
            <Col>
              <Row className="justify-content-md-center">
                <Col xs={12} md={6} className="horizontalAlign">
                  <PhoneNumber dark contactPage/>
                </Col>
                <Col xs={12} md={6} className="horizontalAlign">
                  <Email dark contactPage/>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              { this.state.mailSent === true ?
                <Card style={ thankYou }>
                  <h2>{this.state.name ? i18n.t('thanks_space.span') + this.state.name + "! " : i18n.t('thanks.span') + ", "}Your message has been sent.</h2>
                  <h4>Whilst you're here, would you like to be added to our email marketing list?</h4>
                  <Row>
                    <Col>
                      <Button variant="primary" onClick={() => this.setState({hidden: true})}>{i18n.t('no_thanks.span')}</Button>
                    </Col>
                    <Col>
                      <Button variant="primary" type="submit" onClick={ () => this.handleMailchimp() }>{i18n.t('send_love.span')}</Button>
                    </Col>
                  </Row>
                  <i style={{marginTop:'30px'}}>{i18n.t('secured_information.span')}</i>
                </Card>
              : null }
              <Form className={ "contact_form " + (this.state.mailSent === true ? 'sent' : 'notsent') } onSubmit={this.handleSubmit}>
                <Form.Control
                  type="name"
                  required
                  placeholder={i18n.t('your_name.span')}
                  value={this.state.name}
                  onChange={e => this.setState({ name: e.target.value })}
                />
                <Form.Control
                  type="number"
                  required
                  placeholder={i18n.t('your_phone.span')}
                  value={this.state.phone}
                  onChange={e => this.setState({ phone: e.target.value })}
                />
                <Form.Control
                  type="email"
                  required
                  placeholder={i18n.t('your_email.span')}
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                />
                <Form.Control
                  as="textarea"
                  required
                  placeholder={i18n.t('your_message.span')}
                  value={this.state.message}
                  onChange={e => this.setState({ message: e.target.value })}
                  rows="6"
                />
                <Button variant="primary" type="submit">{i18n.t('send_your_message.span')}</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      );
    } else {
      return (
        <Container className="contact-form_container">
          <Row>
            <Col>
              <Row style={introText}>
                <h1 style={introTitle}>WE WOULD LOVE TO HEAR FROM <i>YOU</i></h1>
                <br/>
                <p>Welcome ipsum dolor sit amet, consectetur adipiscing elit. Donec  ut erat pellentesque metus condimentum eleifend eget sed nibh. Aenean libero tellus, convallis ut leo et, consectetur vestibulum nisl. Phasellus gravida vel ex quis semper. Integer pellentesque sapien id scelerisque. Lorem ipsum dolor sit amet,</p>
                <br/>
              </Row>
              <Row className="justify-content-md-center">
                <Col xs={12} md={6} className="horizontalAlign">
                  <PhoneNumber dark contactPage/>
                </Col>
                <Col xs={12} md={6} className="horizontalAlign">
                  <Email dark contactPage/>
                </Col>
              </Row>
              <Card style={ thankYou }>
                <h2>{this.state.name ? i18n.t('thanks_space.span') + this.state.name + "! " : i18n.t('thanks.span') + ", "}Thats all set up! <span role="img" aria-label="love">❤️</span></h2>
                <h4>Don't forget you can unsubscribe at any point using 'unsubscribe' link in the footer of any of our marketing emails.</h4>
              </Card>
            </Col>
          </Row>

        </Container>
      );
    }
  }
}

export default ContactForm;
