import React, { Component } from 'react';
import i18n from '../i18n';
import axios from "axios";

class Email extends Component {

  state = {
    email: [],
    isLoading: true,
    errors: null
  };

  getConfig() {
  axios
    .get( process.env.REACT_APP_ACF_API + "options/acf-options-general/")
    .then(response => {
      this.setState({
        email: response.data.acf.email,
      });
    })
    .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    this.getConfig();
  }

  render() {
    if( this.state.email != null){
      return (
        <>
          <a href={'mailto:' + this.state.email} id={this.props.contactPage? "contact-page-link" : null} className={this.props.dark ? "footer_item--dark email" : "footer_item email"}>{i18n.t('send_an_email.span')}</a>
        </>
      )
    } else {
      return (
        null
      )
    }
  }
}

export default Email;
