import React, { Component } from 'react';
import { Container, Col, Row } from 'react-bootstrap'
import PhoneNumber from '../Calls/PhoneNumber'
import Social from '../Calls/Social'
import Email from '../Calls/Email'

class PreFooter extends Component {

  render() {
    return (
      <Container style={{marginTop: '30px'}} fluid className="footer justify-content-center">
        <Container>
          <Row xs={12} md={12} lg={12} style={{ margin: '0 auto' }}>
            <Col xs={12} md={3} lg={4} className="horizontalAlign">
              <Social white/>
            </Col>

            <Col xs={12} md={5} lg={4} className="horizontalAlign">
              <PhoneNumber/>
            </Col>

            <Col xs={12} md={4} lg={4} className="horizontalAlign">
              <Email/>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }

}

export default PreFooter;
