import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import Intro from '../../Content/Intro';
import Map from './Components/Map';
import i18n from '../../i18n';

class Step3 extends Component {

  constructor(props) {
      super(props)
      this.state = {
        maxStep: 3,
      }
      if( this.state.maxStep < 3 ){
        this.state = {
          maxStep: 3,
        }
      }
    }

  render() {



    if (this.props.currentStep !== 3) { // Prop: The current step
      return null
    }
    return (
      <Container>
        <Row>
          <h3 className="step">{i18n.t('step3.span')}</h3>
        </Row>
        <Intro
        title="Select Route"
        text="Please select your preferred route. Note that your selected route may change due to weather conditions."
        removeLine
        />
        <Map
        getRoute={this.props.getRoute}
        next={this.props.next}
        />
      </Container>
    );
  }

}

export default Step3;
