import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import i18n from '../i18n';


const center = {
  textAlign: 'center',
}


class DayRoutes extends Component {

  render() {
    return (
      <Container id="dayRoutes" fluid className="day-routes">
        <Container>
          <Row className="justify-content-center">
            <Col style={center}>
              <h2>Day <strong>Routes</strong></h2>
              <p>Welcome ipsum dolor sit amet, consectetur adipiscing elit. Donec  ut erat pellentesque metus condimentum eleifend eget sed nibh. Aenean libero tellus, convallis ut leo et, consectetur vestibulum nisl. Phasellus gravida vel ex quis semper. Integer pellentesque sapien id scelerisque. Lorem ipsum dolor sit amet,</p>
            </Col>
          </Row>
          <Row>
            <LoadScript
            id="script-loader"
            googleMapsApiKey='AIzaSyBhKeQncFiIXcmJkTInUjbgH2KTu1DK1GI'
            >
              <GoogleMap
              id='map'
              mapContainerStyle={{
                height: "505px",
                width: "100%"
              }}
              zoom={7}
              center={{
                lat: -8.111345,
                lng: 37.044540
              }}
              ></GoogleMap>
            </LoadScript>
            <Button style={{width: '100%'}}><i>{i18n.t('not_the_route.span')}</i></Button>
          </Row>
        </Container>
      </Container>
    );
  }

}

export default DayRoutes;
