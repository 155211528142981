import React, { Component } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import MoreInformation from './MoreInformation';
import i18n from '../../../../i18n';

class SetMenu extends Component {

  constructor(props) {
      super(props);
      this.addActiveClassOne = this.addActiveClassOne.bind(this);
      this.addActiveClassTwo = this.addActiveClassTwo.bind(this);
      this.addActiveClassThree = this.addActiveClassThree.bind(this);
      this.state = {
          activeOne: false,
          activeTwo: false,
          activeThree: false,
      };
  }

  addActiveClassOne() {
      const currentState = this.state.activeOne;
      this.setState({
        activeOne: !currentState,
        activeTwo: false,
        activeThree: false,
      });
      this.props.updatePriceForFood( 60 );
  };

  addActiveClassTwo() {
      const currentState = this.state.activeTwo;
      this.setState({
        activeOne: false,
        activeTwo: !currentState,
        activeThree: false,
      });
      this.props.updatePriceForFood( 50 );
  };

  addActiveClassThree() {
      const currentState = this.state.activeThree;
      this.setState({
        activeOne: false,
        activeTwo: false,
        activeThree: !currentState,
      });
      this.props.updatePriceForFood( 50 );
  };

  render() {
    return (
      <>
      <Row>
        <Row style={{width: '100%', paddingLeft: '27px', paddingTop: '0px'}}>
        <strong>{i18n.t('menu_options.span')}:</strong>
        </Row>
        <Row style={{width: '100%', paddingTop: '0px'}}>
         <Col md={4}>
          <Card className={ this.state.activeOne ? "menuCard active" : "menuCard"} onClick={this.addActiveClassOne} >
            <h3>{i18n.t('menu.span')} 1</h3>
            <hr/>
            <span>
            Coast Prawns <br/>
            Clams<br/>
            Portguese Stuffed Crab<br/>
            Algrarvian Salad & Bread
            </span>
            <p className="price">€60</p>
            <p className="postPrice">{i18n.t('per_person.span')}</p>
          </Card>
         </Col>
         <Col md={4}>
           <Card className={ this.state.activeTwo ? "menuCard active" : "menuCard"} onClick={this.addActiveClassTwo} >
             <h3>{i18n.t('menu.span')} 2</h3>
             <hr/>
             <span>
             Octopus Salad<br/>
             Cataplana<br/>
             (combination of different seafoods)
             </span>
             <p className="price">€50</p>
             <p className="postPrice">{i18n.t('per_person.span')}</p>
           </Card>
         </Col>
         <Col md={4}>
           <Card className={ this.state.activeThree ? "menuCard active" : "menuCard"} onClick={this.addActiveClassThree} >
             <h3>{i18n.t('menu.span')} 3</h3>
             <hr/>
             <span>
             Sushi & Sashimi Variety <br/>
             (20 pieces)
             </span>
             <p className="price">€50</p>
             <p className="postPrice">{i18n.t('per_person.span')}</p>
           </Card>
         </Col>
        </Row>
      </Row>
      <MoreInformation/>
      </>
    );
  }

}

export default SetMenu;
