import React, { Component } from 'react';
import {Button} from 'react-bootstrap'
import axios from "axios";

class SocialLinks extends Component {

  state = {
    social: [],
    isLoading: true,
    errors: null
  };

  getConfig() {
  axios
    .get( process.env.REACT_APP_ACF_API + "options/acf-options-general/")
    .then(response => {
      this.setState({
        social: response.data.acf,
      });
    })
    .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    this.getConfig();
  }

  render() {
      return (
        <div className="socialFooterStuff">
          {this.state.social.facebook ? <Button onClick={  () => {window.open(this.state.social.facebook)} } variant="fb" className={this.props.white ? "soc-white soc-footer" : "soc-primary"}></Button> : null}
          {this.state.social.instagram ? <Button onClick={  () => {window.open(this.state.social.instagram)} } variant="in" className={this.props.white ? "soc-white soc-footer" : "soc-primary"}></Button> : null}
        </div>
      )
  }
}

export default SocialLinks;
