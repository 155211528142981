import React, { Component } from 'react';
import { Container, Row, Card, Col, Button } from 'react-bootstrap';

class SingleYacht extends Component {

  render() {


    return (
      <Container>
        <Card className="card-border" id={this.props.id} >
        <Card.Img variant="top" alt={this.props.alt} src={this.props.image} />
        <Card.Body className="singleYacht-selector">
          <Card.Title>{this.props.title.split(" ")[0]}<strong> {this.props.title.split(" ")[1]}</strong></Card.Title>
          <Card.Text>
            {this.props.text}
          </Card.Text>
          <Row>
            <Col>
              <Card.Text className="card-text-from">From:</Card.Text>
              <Card.Text className="card-text-cost">€{this.props.price}</Card.Text>
            </Col>
            <Col>
              <Button size="l" variant="primary" style={{maxWidth:'165px', minWidth: '190px'}} value='yachtid' onClick={() => { this.props.sendYacht(this.props.id, this.props.price, this.props.priceTwo, this.props.priceThree, this.props.priceFour, this.props.priceFive, this.props.priceSubsequent, this.props.priceWeekly ) }} >Book Now</Button>
            </Col>
          </Row>
        </Card.Body>
        </Card>
      </Container>
    );
  }

}

export default SingleYacht;
