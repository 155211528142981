import React, { Component } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';

const ewww = {
  borderWidth: '0px',
}



class Map extends Component {

  constructor(){
     super();
      this.state = {
        pages: [],
        routeAdded: [],
      };
  }

  componentDidMount() {
    let pagesURL = process.env.REACT_APP_GEN_API + 'routes/';
    fetch( pagesURL )
    .then( response => response.json() )
    .then( response => {
      this.setState({
        pages: response
      })
    })
  }

  render() {

    let pages = this.state.pages.map(( page, index ) => {
        return (
            <Card key={index} className="mapCard" style={this.props.style}>
              <Card.Body>
              {page.acf.map_embed_link ?
                <Row style={{ backgroundColor: '#f5f5f5', }} >
                  <iframe title={index} style={ewww} src={page.acf.map_embed_link} width="100%" height="480"></iframe>
                </Row>
                : null }
                <Row style={{ paddingBottom: '0px' }}>
                  <Col>
                    <h2>{ page.title.rendered }</h2>
                  </Col>
                  { this.props.hideButton === true ? null :
                  <Col>
                    { this.props.noButton === true ?
                    <Button className="select_route"onClick={ () => { this.state.routeAdded !== true ? this.setState({ routeAdded: true }) : this.setState({ routeAdded: false })}}>{ this.state.routeAdded !== true ? "Add route" : "Remove Route"}</Button>
                    :
                    <Button className="select_route" disabled={this.state.focusedInput === null} onClick={() => { this.props.getRoute(page.acf.departure_port, page.title.rendered, page.acf.price_adjust_per_day) }}>Select route</Button>}
                  </Col>
                }
                </Row>
              </Card.Body>
            </Card>
        )
    })

    return (
      <>
        {pages}
      </>
    );
  }

}






export default Map;
