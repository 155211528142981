import React, { Component } from 'react';
import {Row, Col, Form} from 'react-bootstrap';
import i18n from '../../../../i18n';
class MoreInformation extends Component {

  render() {
    return (

      <Row>
        <Col>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label>{i18n.t('allergies.span')}</Form.Label>
          <Form.Control as="textarea" rows="3" />
        </Form.Group>
        </Col>
      </Row>

    );
  }

}

export default MoreInformation;
