import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

class WineList extends Component {

  constructor(props) {
      super(props)
      this.state = {
        wineQuantity: 0,
      }
      this.increment = this.increment.bind(this)
      this.decrement = this.decrement.bind(this)
      this.handleChange = this.handleChange.bind(this)
    }

  increment(e){
    this.setState({wineQuantity: this.state.wineQuantity + 1}, () => {
      this.handleChange()
    });
  }

  decrement(e){
    this.setState({wineQuantity: this.state.wineQuantity - 1}, () => {
      this.handleChange()
    });
  }

  handleChange() {
    this.props.pushArray(this.props.wineId, this.props.wineName, this.props.winePrice, this.state.wineQuantity, (this.props.winePrice * this.state.wineQuantity) )
  }

  render() {
    return (
      <>
        <li style={{ listStyle: 'none', }}>
          <Row>
            <Row>
              <Col md={12} style={{ paddingLeft: '0px', paddingRight: '0px', }}><h5>{this.props.wineName}</h5></Col>
            </Row>
            <Row>
              <Col md={12}><p>{this.props.winePrice}€</p></Col>
            </Row>
              <Col md={12}>
                <div style={{ margin: '0 auto', width: '120px' }}>
                { this.state.wineQuantity >= 1 ?
                  <Button onClick={this.decrement} variant='nostyleQuantity'>-</Button>
                  :
                  <Button onClick={this.decrement} variant='nostyleQuantity' style={{ opacity: '0', pointerEvents: 'none' }}>-</Button>
                }
                <Form.Control type="quantity" readOnly onChange={(e) => this.setState({ wineQuantity: e.target.value })} value={this.state.wineQuantity} style={{ textAlign: 'center', float: 'left', width: 'auto', maxWidth: '49px', height: '42px' }}/>
                <Button onClick={this.increment} variant='nostyleQuantity'>+</Button>
                </div>
              </Col>
          </Row>
        </li>
      </>
    );
  }

}

export default WineList;
