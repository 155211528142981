import React, { Component } from 'react';
import Step1 from './BookExpParts/Step1';
import Step2 from './BookExpParts/Step2';
import Step3 from './BookExpParts/Step3';
import Step4 from './BookExpParts/Step4';
import Step5 from './BookExpParts/Step5';
import Step6 from './BookExpParts/Step6';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import i18n from '../i18n';
import moment from 'moment';

class MasterForm extends Component {

  constructor(props) {
      super(props)
      this.state = {
        currentStep: 1,
        maxStep: 1,
        yachtSelected: null,
        startDate: null,
        endDate: null,
        startDateString: null,
        endDateString: null,
        numberOfDays: null,
        numberOfGuests: 1,
        drinksSelected: [],
        drinksPrice: null,
        foodSelected: [],
        foodPrice: null,
        currentPrice: null,
        selectedRoute: null,
        departurePort: null,
        depositPrice: null,
        basePrice: null,
        basePriceTwo: null,
        basePriceThree: null,
        basePriceFour: null,
        basePriceFive: null,
        basePriceSubsequent: null,
        basePriceWeekly: null,
      }
      this.getYacht = this.getYacht.bind(this)
      this.getDates = this.getDates.bind(this)
      this.getRoute = this.getRoute.bind(this)
      this.one = this.one.bind(this)
      this.two = this.two.bind(this)
      this.three = this.three.bind(this)
      this.four = this.four.bind(this)
      this.five = this.five.bind(this)
      this.six = this.six.bind(this)
      this._next = this._next.bind(this)
      this._boatNext = this._boatNext.bind(this)
      this._prev = this._prev.bind(this)
      this.handleChange = this.handleChange.bind(this)
      this.getClient = this.getClient.bind(this)
      this.submit = this.submit.bind(this)
      this.checkDeposit = this.checkDeposit.bind(this)
      this.updateGuestNumbers  = this.updateGuestNumbers.bind(this)
      this.updatePriceForFood = this.updatePriceForFood.bind(this)
      this.updatePriceForDrinks = this.updatePriceForDrinks.bind(this)
    }

    handleChange(event) {
      const {name, value} = event.target
      this.setState({
        [name]: value
      })
    }

    _next() {
      this.setState({
        currentStep: this.state.currentStep + 1,
      })
      this.checkDeposit();
      if( this.state.maxStep === this.state.currentStep ){
        this.setState({
          maxStep: this.state.maxStep + 1,
        })
      }
    }

    _boatNext() {
      this.setState({
        currentStep: this.state.currentStep + 1,
      })
      if( this.state.maxStep === this.state.currentStep ){
        this.setState({
          maxStep: this.state.maxStep + 1,
        })
      }

    }

    _prev() {
      this.setState({
        currentStep: this.state.currentStep - 1,
      })
    }

    one() {
      this.setState({
        currentStep: 1,
      })
    }
    two() {
      this.setState({
        currentStep: 2,
      })
    }
    three() {
      this.setState({
        currentStep: 3,
      })
    }
    four() {
      this.setState({
        currentStep: 4,
      })
    }
    five() {
      this.setState({
        currentStep: 5,
      })
    }
    six() {
      this.setState({
        currentStep: 6,
      })
    }

    getYacht(id, price, priceTwo, priceThree, priceFour, priceFive, priceSubs, priceWeekly){
      this.setState({
        basePrice: price,
        basePriceTwo: priceTwo,
        basePriceThree: priceThree,
        basePriceFour: priceFour,
        basePriceFive: priceFive,
        basePriceSubsequent: priceSubs,
        basePriceWeekly: priceWeekly,
        yachtSelected: id,
        currentStep: this.state.currentStep + 1,
      })
      if( this.state.maxStep === this.state.currentStep ){
        this.setState({
          maxStep: this.state.maxStep + 1,
        })
      }
    }

    getDates(start, end){
      var startDate =  start ? new Date(start) : new Date(end);
      var startDateString = start ? moment(start).format('LLLL') : moment(end).format('LLLL');
      var endDate = end ? new Date(end) : new Date(start);
      var endDateString = end ? moment(end).format('LLLL') : moment(start).format('LLLL');
      var days = Math.round((endDate - startDate) / (1000*60*60*24) + 1 );
      var priceCalc = null;
      if( days === 1 ){
        priceCalc = this.state.basePrice
      } else if( days === 2 ) {
        priceCalc = this.state.basePriceTwo ? this.state.basePriceTwo : this.state.basePrice
      } else if( days === 3 ) {
        priceCalc = this.state.basePriceThree ? this.state.basePriceThree : this.state.basePrice
      } else if( days === 4 ) {
        priceCalc = this.state.basePriceFour ? this.state.basePriceFour : this.state.basePrice
      } else if( days === 5 ) {
        priceCalc = this.state.basePriceFive ? this.state.basePriceFive : this.state.basePrice
      } else if( days > 5 && days < 7 ) {
        priceCalc = this.state.basePriceSubsequent ? this.state.basePriceSubsequent : this.state.basePrice
      } else if( days === 7 ) {
        priceCalc = this.state.basePriceWeekly ? this.state.basePriceWeekly / 7 : this.state.basePrice
      } else if( days > 7 ) {
        priceCalc = this.state.basePriceSubsequent ? this.state.basePriceSubsequent : this.state.basePrice
      }
      this.setState({
        startDate: startDate,
        endDate: endDate,
        startDateString: startDateString,
        endDateString: endDateString,
        days: days,
        numberOfDays: days,
        currentPrice: days * priceCalc,
        currentStep: this.state.currentStep + 1,
      })
      if( this.state.maxStep === this.state.currentStep ){
        this.setState({
          maxStep: this.state.maxStep + 1,
        })
      }
    }

    getRoute(departurePort, route, adjust){
      var addToCost = this.state.currentPrice * adjust;
      this.setState({
        currentPrice: this.state.currentPrice + addToCost,
        departurePort: departurePort,
        selectedRoute: route,
        currentStep: this.state.currentStep + 1,
      })
      if( this.state.maxStep === this.state.currentStep ){
        this.setState({
          maxStep: this.state.maxStep + 1,
        })
      }
    }

    updateGuestNumbers(guests){
      this.setState({
        numberOfGuests: guests
      })
    }

    updatePriceForFood(foodPrice){
      var addToCost = foodPrice * this.state.numberOfGuests;
      this.setState({
        foodPrice: addToCost,
        currentPrice: this.state.currentPrice + addToCost,
      })
    }

    updatePriceForDrinks(drinksPrice){
      var addToCost = drinksPrice;
      this.setState({
        drinksPrice: this.state.drinksPrice + addToCost,
        currentPrice: this.state.currentPrice + addToCost,
      })
    }

    checkDeposit(){
      this.setState({
        depositPrice: (this.state.currentPrice / 100) * 25,
      })
    }



    getClient(name, number, address, address1, address2, city, state, zipCode, partnerNumber){
      const obj = {'name': name, 'number': number, 'address': address, 'address1': address1, 'address2': address2, 'city': city, 'state': state, 'zipCode': zipCode, 'partnerNumber': partnerNumber };
      this.setState({
        clientDetails: Object.assign({}, this.state.clientDetails, obj),
      })
    }

    async submit(ev) {
      console.log('form submitted!: ')
      console.log('client: ' + this.state.clientDetails)
      console.log('currentPrice: ' + this.state.currentPrice)
      console.log('departurePort: ' + this.state.departurePort)
      console.log('depositPrice: ' + this.state.depositPrice)
      console.log('numberOfDays: ' + this.state.numberOfDays)
      console.log('selectedRoute: ' + this.state.selectedRoute)
      console.log('startDate: ' + this.state.startDateString)
    }

  render() {
    return (
      <>
      <Container className="subHeader" fluid>
        <Container id="headerHolder">
          <Row style={{ paddingTop: '10px', paddingBottom: '10px' }} className="d-none d-md-flex">
            <Col xs={12} md={2} className="horizontalAlign">
              <Button variant="booking" onClick={this.one}>{i18n.t('select_yacht.span')}</Button>
            </Col>
            <Col xs={12} md={2} className="horizontalAlign">
              <Button variant="booking" onClick={this.two} disabled={this.state.maxStep < 2}>{i18n.t('select_date.span')}</Button>
            </Col>
            <Col xs={12} md={2} className="horizontalAlign">
              <Button variant="booking" onClick={this.three} disabled={this.state.maxStep < 3}>{i18n.t('select_route.span')}</Button>
            </Col>
            <Col xs={12} md={2} className="horizontalAlign">
              <Button variant="booking" onClick={this.four} disabled={this.state.maxStep < 4}>{i18n.t('extras.span')}</Button>
            </Col>
            <Col xs={12} md={2} className="horizontalAlign">
              <Button variant="booking" onClick={this.five} disabled={this.state.maxStep < 5}>{i18n.t('payment.span')}</Button>
            </Col>
            <Col xs={12} md={2} className="horizontalAlign">
              <Button variant="booking" onClick={this.six} disabled={this.state.maxStep < 6}>{i18n.t('confirmation.span')}</Button>
            </Col>
          </Row>
          { this.state.currentStep > 1 ?
          <Row style={{ paddingTop: '0px', paddingBottom: '0px' }} className="d-xs-flex d-md-none">
            <Col style={{ width: '100%' }} className="horizontalAlign">
              <Button style={{ width: '100%' }} variant="booking" className="backarrow" onClick={this._prev}>{i18n.t('back.span')}</Button>
            </Col>
          </Row>
          : null }

        </Container>
      </Container>
      <Container>
        <Form className="form">
          <Step1
            sendYacht={this.getYacht}
            currentStep={this.state.currentStep}
            maxStep={this.state.maxStep}
            handleChange={this.handleChange}
            next={this._boatNext}
          />
          <Step2
            addDateToCal={this.addDateToCal}
            getDates={this.getDates}
            currentStep={this.state.currentStep}
            maxStep={this.state.maxStep}
            handleChange={this.handleChange}
            next={this._next}
          />
          <Step3
            getRoute={this.getRoute}
            currentStep={this.state.currentStep}
            maxStep={this.state.maxStep}
            handleChange={this.handleChange}
            next={this._next}
          />
          <Step4
            currentStep={this.state.currentStep}
            maxStep={this.state.maxStep}
            days={this.state.days}
            handleChange={this.handleChange}
            updateGuestNumbers={this.updateGuestNumbers}
            updateDrinksNumbers={this.updateDrinksNumbers}
            updatePriceForDrinks={this.updatePriceForDrinks}
            updatePriceForFood={this.updatePriceForFood}
            foodPrice={this.state.foodPrice}
            drinksPrice={this.updatePriceForDrinks}
            next={this._next}
          />
          <Step5
            checkDeposit={this.checkDeposit}
            departurePort={this.state.departurePort}
            startDate={this.state.startDateString}
            endDate={this.state.endDateString}
            numberOfDays={this.state.numberOfDays}
            selectedRoute={this.state.selectedRoute}
            currentPrice={this.state.currentPrice}
            depositPrice={this.state.depositPrice}
            currentStep={this.state.currentStep}
            maxStep={this.state.maxStep}
            handleChange={this.handleChange}
            next={this._next}
            getClient={this.getClient}
            submit={this.submit}
          />
          <Step6
            currentStep={this.state.currentStep}
            maxStep={this.state.maxStep}
            handleChange={this.handleChange}
          />
        </Form>
      </Container>
      </>
    );
  }
}

export default MasterForm;
