import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap'
import i18n from '../../i18n';


class Stats extends Component {

  render() {
    return (
      <Container>
        <Row className="justify-content-md-center">
          {this.props.meters ?
          <Col xs={6} md={2} lg={1}>
            <Button variant="outline outline-meters">{this.props.meters}</Button>
          </Col>
          : null}
          {this.props.guests ?
          <Col xs={6} md={2} lg={1}>
            <Button variant="outline outline-guests">{this.props.guests}</Button>
          </Col>
          : null}
          {this.props.knots ?
          <Col xs={6} md={2} lg={1}>
            <Button variant="outline outline-knots">{this.props.knots}</Button>
          </Col>
          : null}
          {this.props.crew ?
          <Col xs={6} md={2} lg={1}>
            <Button variant="outline outline-crew">{this.props.crew}</Button>
          </Col>
          : null}
        </Row>
        {this.props.price ?
        <Row>
          <Col>
            <span className="price">€{this.props.price}<sub>/ {i18n.t('day.span')}</sub></span>
          </Col>
        </Row>
        : null}
      </Container>
    );
  }

}

export default Stats;
