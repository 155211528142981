import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const imageStyle = {
  borderRadius: '0px',
  maxHeight: '532px',
}

class Slider extends Component {

  componentDidMount(){
    document.title = "Sunseeker Portugal Lifestyle | " + this.props.title;
  }

  render() {
    return (
      <Card fluid="true" className="slider bg-dark text-white" style={{ border: '0px' }}>
        <Card.Img src={ this.props.imageSrc } border="0px" alt={ this.props.imageAlt } style={ imageStyle } />
        <Card.ImgOverlay className="sliderOverlay">
          <div className="sliderOverlay_inner">
          {this.props.title ?
            <Card.Title className="sliderOverlay_title">{this.props.title}</Card.Title>
            : null
          }
          {this.props.text ?
            <Card.Text className="sliderOverlay_text">
              {this.props.text}
            </Card.Text>
            : null
          }
          {this.props.button ?
            <NavLink to={this.props.buttonLink} className="btn a-primary">{this.props.button}</NavLink>
            : null }
          {this.props.singleYacht?
            <button className="btn-downarrow"></button>
          : null }
          </div>
        </Card.ImgOverlay>
      </Card>
    );
  }

}

export default Slider;
