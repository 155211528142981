import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Stats from './Partial/Stats.js';
import PropTypes from 'prop-types';

const introText = {
  textAlign: "center",
}

const introTitle = {
  width: '100%',
}

class Intro extends Component {

  componentDidMount(){
    document.title = "Sunseeker Portugal Lifestyle | " + this.props.title;
  }

  render() {
    var str = this.props.title;
    var lastIndex = str.lastIndexOf(" ");
    var firstPart = str.substring(0, lastIndex);
    return (
      <Container id={this.props.id}>
        <Row>
          <Col>
            <Row style={introText}>
              <h1 style={introTitle}>{ firstPart }<strong> {this.props.title.split(" ").pop()}</strong></h1>
              <br/>
              <div style={(this.props.meters || this.props.guests || this.props.knots || this.props.crew || this.props.price ) ? { width: '954px', margin: '0 auto', fontWeight: '300', fontSize: '20px', } : { fontFamily: "Source Sans Pro", fontWeight: '300', fontSize: '20px', lineHeight: '28px', marginBottom: '30px', width: '954px', margin: '0 auto'}} dangerouslySetInnerHTML={{ __html: this.props.text }}/>
              {(this.props.meters || this.props.guests || this.props.knots || this.props.crew || this.props.price ) ?
                <Stats
                  meters={this.props.meters}
                  guests={this.props.guests}
                  knots={this.props.knots}
                  crew={this.props.crew}
                  price={this.props.price}
                />
              : null}
              {this.props.buttonLink ?
              <Col md={12}>
                <Button style={introText} onClick={ () => window.open( this.props.buttonLink )}>{this.props.buttonTitle}</Button>
              </Col>
              : null }
              <br/>
              {this.props.removeLine ?
              null
              : <div style={{ marginTop: '60px', width: '100%', float: 'left', }}>
              <div className="horixontal" />
              </div> }
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

}

Intro.propTypes = {
  title:  PropTypes.string,
  text:  PropTypes.string,
  meters: PropTypes.string,
  guests: PropTypes.string,
  knots: PropTypes.string,
  crew: PropTypes.string,
  price: PropTypes.string,
  removeLine: PropTypes.bool,
  buttonLink: PropTypes.string,
  buttonTitle: PropTypes.string,
};

export default Intro;
