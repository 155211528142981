import React, { Component } from 'react';
import { Container, Col, Row, Card } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

import featImageTwoImg from '../../images/day-trips.jpg';
import featImageThreeImg from '../../images/luxury-experiences.jpg';


const imgTwo = {
  src: featImageTwoImg,
  alt: 'Yacht Two',
}

const imgThree = {
  src: featImageThreeImg,
  alt: 'Yacht Three',
}

const cardOverlay = {
  textAlign: 'center',
  bottom: '30px',
  boarder: '1px solid white',
}

class TheExperiance extends Component {

  render() {
    if(this.props.kill_exp === true){
      return null
    } else {
      return (
        <Container>
          <Row className="the-experiences">
            <Col>
              <h2>The <strong>Experiences</strong></h2>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="previewHolder">
                <NavLink to="/our-yachts/luma">
                  <Card className="the-experiences-single odd-experiences text-white">
                    <Card.Img src={ imgThree.src } alt={ imgThree.src }/>
                    <Card.ImgOverlay style={cardOverlay} className="overlay">
                      <Card.Title>Day Trips</Card.Title>
                    </Card.ImgOverlay>
                    <Card.ImgOverlay style={cardOverlay} className="overlayHover">
                      <div className="overlayHover">
                        <Card.Title>Day Trips</Card.Title>
                        <Card.Text></Card.Text>
                      </div>
                    </Card.ImgOverlay>
                  </Card>
                </NavLink>
              </div>
            </Col>
            <Col md="6">
              <div className="previewHolder">
                <NavLink to="/luxury-experiences">
                  <Card className="the-experiences-single even-experiences text-white">
                    <Card.Img src={ imgTwo.src } alt={ imgTwo.src }/>
                    <Card.ImgOverlay style={cardOverlay} className="overlay">
                      <Card.Title>Luxury Experiences</Card.Title>
                    </Card.ImgOverlay>
                    <Card.ImgOverlay style={cardOverlay} className="overlayHover">
                      <div className="overlayHover">
                        <Card.Title>Luxury Experiences</Card.Title>
                        <Card.Text></Card.Text>
                      </div>
                    </Card.ImgOverlay>
                  </Card>
                </NavLink>
              </div>
            </Col>
          </Row>
        </Container>
      );
    }
  }

}

export default TheExperiance;
