import React, { Component } from 'react';
import { Container, Row, Col, Form, Card, Accordion, Button } from 'react-bootstrap';
import i18n from '../../../i18n';

class SpecialRequests extends Component {

  constructor() {
    super()
    this.state = {
      other: false,
    }
  }

  render() {
    return (
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="4">
            {i18n.t('special_requests.span')}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="4">
          <Card.Body>
            <Container>
              <Row style={{paddingTop: '0px'}}>
                <Col style={{ paddingLeft: '0px' }}>
                  <Form.Group controlId="formBasicChecbox">
                    <Form.Check type="checkbox" label="birthday" />
                    <Form.Check type="checkbox" label="proposal" />
                    <Form.Check type="checkbox" label="surprise charter" />
                    <Form.Check type="checkbox" label="other" onClick={ () => {this.state.other !== true ? this.setState({ other: true}) : this.setState({ other: false})} } />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formBasicChecbox">
                    { this.state.other === true ?
                      <>
                        <Form.Label>Other:</Form.Label>
                        <Form.Control as="textarea" rows="6" />
                      </>
                    :
                      null
                    }
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ paddingBottom: '6px' }}>
                <Accordion.Toggle as={Button} variant="primary" eventKey="6">{i18n.t('next_section.span')}</Accordion.Toggle>
              </Row>
            </Container>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}

export default SpecialRequests;
