import React, { PureComponent } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import MenuParent from './MenuSingle/MenuParent';
import MenuItem from './MenuSingle/MenuItem';

class Menu extends PureComponent {

  constructor(){
     super();
      this.state = {
        pages: []
      };
  }

  componentDidMount() {
    let pagesURL = process.env.REACT_APP_MENU_API;
    fetch( pagesURL )
    .then( response => response.json() )
    .then( response => {
      this.setState({
        pages: response
      })
    })
  };

  render() {
    let pages = this.state.pages.map(( page, index ) => {
      if( 'parent' !== String(page.classes) && '0' === String(page.menu_item_parent) ){
        return (
          <MenuItem
            key={ index }
            to={{ pathname: page.slug, state: 'flushDeal' }}
            classes={page.classes}
            title={page.title}
          />
        )
      } else if( 'parent' === String(page.classes) ){
        return (
          <MenuParent
            key={ index }
            to={page.slug}
            title={page.title}
          />
        )
      } else {
        return (
          null
        )
      }
    })
    return (
      <>
        <Navbar className="menu" expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav style={{margin: '0 auto'}} className="justify-content-center">
              {pages}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}
export default Menu;
