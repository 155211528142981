import React, { Component } from 'react';
import { Container, Row, Col, Card, Accordion, Button } from 'react-bootstrap';
import SetMenu from './DiningOnBoard/SetMenu';
import ChefOnboard from './DiningOnBoard/ChefOnboard';
import i18n from '../../../i18n';

class DiningOnBoard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      diningExperiance: '',
    }
  }


  render() {
    return (
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            {i18n.t('dining_on_board.span')}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Container>
              <Row style={{paddingTop: '0px'}}>
                <Row style={{paddingLeft: '27px', paddingTop: '0px'}}>
                  <strong>Your charter pack includes:</strong>
                </Row>
                <Row style={{width: '100%'}}>
                  <Col><p className="preTick">Glass of champagne on arrival</p></Col>
                  <Col><p className="preTick">Soft drinks</p></Col>
                  <Col><p className="preTick">White wine and Rose</p></Col>
                  <Col><p className="preTick">Savoury snacks and fruit</p></Col>
                </Row>
              </Row>
              <hr/>
              <Row>
                <Row style={{width: '100%', paddingLeft: '27px', paddingTop: '0px'}}>
                <strong>Choose your dining experience:</strong>
                </Row>
                <Row style={{width: '100%'}}>
                  <Col md={6} ><Button className={ this.state.diningExperiance === 'setmenu' ? "on" : "off" } onClick={ () => { this.state.diningExperiance !== 'setmenu' ? this.setState({ diningExperiance: 'setmenu' }) : this.setState({ diningExperiance: '' }) }} size='l' style={{ width: '100%' }}>Set Menu</Button></Col>
                  <Col md={6} ><Button className={ this.state.diningExperiance === 'chef' ? "on" : "off" } onClick={ () => { this.state.diningExperiance !== 'chef' ? this.setState({ diningExperiance: 'chef' })  : this.setState({ diningExperiance: '' }) }} size='l' style={{ width: '100%' }}>Chef On-Board</Button></Col>
                </Row>
              </Row>
              {this.state.diningExperiance === 'chef' ?
                <>
                <hr/>
                <ChefOnboard updatePriceForFood={this.props.updatePriceForFood} />
                </>
              :
                null
              }
              {this.state.diningExperiance === 'setmenu' ?
                <>
                <hr/>
                <SetMenu updatePriceForFood={this.props.updatePriceForFood} />
                </>
              :
                null
              }

              <Row style={{ paddingBottom: '6px' }}>
                <Accordion.Toggle as={Button} variant="primary" eventKey="1">{i18n.t('next_section.span')}</Accordion.Toggle>
              </Row>
            </Container>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }

}

export default DiningOnBoard;
