import React, { Component } from 'react';
import Slider from '../Components/PageParts/Slider';
import ContactForm from '../Components/Forms/ContactForm';
import {Container, Row} from 'react-bootstrap';
import sliderImageFile from '../images/tmp/contactHeader.png';
import i18n from '../Components/i18n';

const sliderImage = {
  src: sliderImageFile,
  alt: 'this is an image',
};

const introText = {
  textAlign: "center",
}

const introTitle = {
  width: '100%',
}

class ContactUs extends Component {

  constructor() {
    super();
    this.state = {
      pages: [],
      loading: true,
    };
  }

  componentDidMount() {
    const pagesURL = `${process.env.REACT_APP_GEN_API}pages/?slug=contact`;
    fetch(pagesURL)
      .then(response => response.json())
      .then((response) => {
        this.setState({
          pages: response,
          loading: false
        });
      });
  }



  render() {

    const pageContent = this.state.pages.map((page, index) => {
      if (page.content.rendered){
        return (
          <Container key={page.id}>
            <Row style={introText}>
              <h1 style={introTitle}>WE WOULD LOVE TO HEAR FROM <strong>YOU</strong></h1>
              <br/>
              <div style={{ textAlign: 'center', width: '100%' }} dangerouslySetInnerHTML={{ __html: page.content.rendered }}></div>
              <br/>
            </Row>
          </Container>
        );
      } else {
        return (
          null
        );
      }
    })

    const slider = this.state.pages.map((page, index) => {
      if (page.better_featured_image.source_url) {
        return (
          <Slider
            imageSrc={page.better_featured_image.source_url}
            imageAlt={page.better_featured_image.alt_text}
            title={page.title.rendered}
          />
        );
      } else {
        return (
          <Slider
            imageSrc={sliderImage.src}
            imageAlt={sliderImage.alt}
            title={i18n.t('contact_us.span')}
            text="For booking enquires of questions use the form below"
          />
        );
      }

    });

    if(this.state.loading === true){
      return(
        <>
        </>
      )
    } else {
      return (
        <>
          {slider}
          {pageContent}
          <ContactForm />
        </>
      );
    }
  }
}

export default ContactUs;
