import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const textstyle = {
  marginBottom: '0px',
}

class WineBasket extends Component {

  render() {

    let inBasket = this.props.inBasket.map(( wine ) => {
      if( wine.quantity >= 1 ){
        return (
          <Row className="in_the_bar" style={{ minHeight: '11px', paddingTop: '0px' }} key={wine.id}>
            <Col>
              <p style={textstyle}>
                {wine.name}
              </p>
            </Col>
            <Col>
              <p style={textstyle}>
                {wine.quantity} bottles
              </p>
            </Col>
            <Col className="in_the_bar_price">
              <p style={textstyle}>
                {(wine.quantity) * wine.price}€
              </p>
            </Col>
          </Row>
        )
      } else {
        return null;
      }
    })
    if(this.props.inBasket.length !== 0){
      return (
        <>
          <hr/>
          <Container>
            <Row>
              <Col>
                <h4>In the bar:</h4>
                {inBasket}
                <hr style={{ borderStyle: 'dashed', marginLeft: '10px' }}/>
                <b style={{ float: 'right', marginRight: '25px', marginLeft: '10px', fontSize: '25px' }}>Total: {this.props.inBasket.reduce((prev,next) => prev + next.totalQuantity,0)}€</b>
              </Col>
            </Row>
          </Container>
        </>
      );
    } else {
      return null;
    }
  }
}

export default WineBasket;
