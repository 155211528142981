import React, { Component } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Intro from '../../Content/Intro';
import StripeCheckout from 'react-stripe-checkout';
import NumberFormat from 'react-number-format';
import i18n from '../../i18n';
import $ from 'jquery';
import Summery from './Components/Summary';

class Step5 extends Component {

  constructor(props) {
      super(props);
      this.state = {
        name: '',
        number: '',
        email: '',
        address: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        message: '',
        partnerNumber: '',
        dateStart: '',
        dateEnd: '',
        mailSent: false,
        error: null
      }
      this.handleSubmit = this.handleSubmit.bind(this)
    }

  componentDidMount(){
    var e = document.createElement("script");
        e.src = 'https://js.stripe.com/v3/';
        e.type="text/javascript";
        document.getElementsByTagName("head")[0].appendChild(e);

    var s = document.createElement("script");
        s.src = 'https://apis.google.com/js/client.js';
        s.type="text/javascript";
        document.getElementsByTagName("head")[0].appendChild(s);
  }

  handlePayment(deposit){
    var stripe = window.Stripe('pk_test_UlbUv2l0cBl5DxvFWMVnpnxo00iBNPUncf');
    var elements = stripe.elements();
    var style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    var card = elements.create('card', {style: style});
    card.mount('#card-element');


  card.addEventListener('change', function(event) {
    var displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  });

  var form = document.getElementById('payment-form');
  form.addEventListener('submit', function(event) {
    event.preventDefault();

    stripe.createToken(card).then(function(result) {
      if (result.error) {
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
      } else {
        stripeTokenHandler(result.token);
      }
    });
  });


  function stripeTokenHandler(token) {
    var form = document.getElementById('payment-form');
    var hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'stripeToken');
    hiddenInput.setAttribute('value', token.id);
    form.appendChild(hiddenInput);
    form.submit();
  }
  }

  submitForm(event){
    const theDate = this.state;
    $.ajax({
        data: theDate,
        type: 'POST',
        url: process.env.REACT_APP_API_BOOKING_MESSAGE_PATH,
        success: function(data) {
        },
        error: function(xhr, status, err) {
        }
      })
      this.setState({
        mailSent: true,
      })
  }

  submitDates(event){
    const theDate = this.state;
    $.ajax({
      data: theDate,
      type: 'POST',
      url: process.env.REACT_APP_GCAL_PATH,
      success: function(data) {
        console.log(data)
      },
      error: function(xhr, status, err) {
        console.log('dates errored')
      }
    })
  }

  updatePriceForDrinks(drinksPrice){
    var addToCost = drinksPrice;
    console.log(addToCost);
    this.setState({
      drinksPrice: this.state.drinksPrice + addToCost,
    })
  }

  handleSubmit(deposit){
    if( deposit !== null){
      this.submitDates();
      this.submitForm();
      this.props.next();
    } else {
      this.submitForm();
      this.props.next();
    }
  }

  onToken = (token) => {
    var additional = {
      realName: this.state.name,
      amount: this.props.depositPrice * 100,
    };
    $.extend( token, additional );
    console.log(token);
    $.ajax({
      data: token,
      type: 'POST',
      url: process.env.REACT_APP_API_BOOKING_PAYMENT_PATH,
      success: function(data) {
        console.log(data);
      },
      error: function(xhr, status, err) {
        console.log('dates errored')
      }
    })

  };

  render() {


    if (this.props.currentStep !== 5) {
      return null
    }

    if (this.state.mailSent === false) {
      return (
      <Container>
        <Row>
          <h3 className="step">{i18n.t('step5.span')}</h3>
        </Row>
        <Intro
        title="Payment Details"
        text="Please proceed with the payment and make sure you have chosen all the items you require."
        removeLine
        />
        <Container>
          <Row>
            <Col className="paymentForm">
              <div className="formBorderTitle">
                <h3>Your Details</h3>
              </div>
              <div className="formBorder">
                <Form.Row onChange={this.setClient} >
                  <Col>
                    <Form.Label>{i18n.t('your_name.span')}</Form.Label>
                    <Form.Control
                      value={this.state.name}
                      onChange={e => this.setState({ name: e.target.value })}
                      type="name"
                      required
                      placeholder=""
                    />

                    <Form.Label>{i18n.t('your_phone.span')}</Form.Label>
                    <Form.Control
                      value={this.state.number}
                      onChange={e => this.setState({ number: e.target.value })}
                      type="number"
                      pattern="[0-9]*"
                      required placeholder=""
                    />

                    <Form.Label>{i18n.t('your_email.span')}</Form.Label>
                    <Form.Control
                      value={this.state.email}
                      onChange={e => this.setState({ email: e.target.value })}
                      type="email"
                      required
                      placeholder=""
                    />

                    <Form.Label>{i18n.t('your_address.span')}</Form.Label>
                    <Form.Control
                      value={this.state.address}
                      onChange={e => this.setState({ address: e.target.value })}
                      type="address"
                      required placeholder=""
                    />

                    <Form.Label>{i18n.t('your_address1.span')}</Form.Label>
                    <Form.Control
                      value={this.state.address1}
                      onChange={e => this.setState({ address1: e.target.value })}
                      type="address1"
                      required placeholder=""
                    />

                    <Form.Label>{i18n.t('your_address2.span')}</Form.Label>
                    <Form.Control
                      value={this.state.address2}
                      onChange={e => this.setState({ address2: e.target.value })}
                      type="address2"
                      placeholder=""
                    />
                    <Row>
                    <Col xs={12} md={4}>
                      <Form.Label>{i18n.t('your_city.span')}</Form.Label>
                      <Form.Control
                        value={this.state.city}
                        onChange={e => this.setState({ city: e.target.value })}
                        type="City"
                        required
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Label>{i18n.t('your_state.span')}</Form.Label>
                      <Form.Control
                        value={this.state.state}
                        onChange={e => this.setState({ state: e.target.value })}
                        type="State"
                        required
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Label>{i18n.t('your_postcode.span')}</Form.Label>
                      <Form.Control
                        value={this.state.zipCode}
                        onChange={e => this.setState({ zipCode: e.target.value })}
                        type="zipCode" required
                      />
                    </Col>
                    </Row>
                    <hr/>

                    <Form.Label>{i18n.t('your_partner.span')}</Form.Label>
                    <Form.Control
                      value={this.state.partnerNumber}
                      onChange={e => this.setState({ partnerNumber: e.target.value })}
                      type="partnerNumber"
                      required
                      placeholder={i18n.t('your_partner_detail.span')}
                    />
                  </Col>
                </Form.Row>
              </div>
              {this.props.depositPrice ?
                <div>
                  <div className="formBorderTitle paymentTitle">
                    <h3>Payment Details</h3>
                  </div>
                  <div id="payment-form" className="formBorder">
                    <Form.Label>VAT number:</Form.Label>
                    <Form.Control type="vat"/>
                    <p>If you are a Portuguese citizen, please enter your VAT number above.</p>
                    <hr/>
                      <Form.Check aria-label="I agree to the Terms & Conditions" label="I agree to the Terms & Conditions" />
                      <br/>
                        <StripeCheckout
                          stripeKey="pk_test_UlbUv2l0cBl5DxvFWMVnpnxo00iBNPUncf"
                          amount={this.props.depositPrice * 100 }
                          currency='EUR'
                          allowRememberMe={false}
                          //email={this.state.email}
                          //customer={this.state.name}
                          token={this.onToken}
                          label={"Pay €" + this.props.depositPrice  + " deposit"}
                        >
                          <Button onClick={()=>this.handleSubmit(this.props.depositPrice)}>Pay <NumberFormat value={ this.props.depositPrice ? this.props.depositPrice.toFixed(2) : '0'} displayType={'text'} thousandSeparator={true} prefix={'€'}/></Button>
                        </StripeCheckout>

                  </div>
                </div>
              :
                <>
                  <div className="formBorderTitle paymentTitle">
                    <h3>Enquire</h3>
                  </div>
                  <div className="formBorder" hidden={this.props.depositPrice > 0}>
                    <Form.Label>{i18n.t('your_message.span')}</Form.Label>
                    <Form.Control
                      key="formessageinput1"
                      as="textarea"
                      rows="3"
                      type="message"
                      required
                      placeholder=""
                      value={this.state.message}
                      onChange={e => this.setState({ message: e.target.value })}
                    />
                    <Form.Check aria-label="I agree to the Terms & Conditions" label="I agree to the Terms & Conditions" />
                    <br/>
                    <Button onClick={()=>this.handleSubmit(null)}>{i18n.t('submit.span')}</Button>
                  </div>
                </>
              }
            </Col>
            <Summery
              startDate={this.props.startDate}
              numberOfDays={this.props.numberOfDays}
              departurePort={this.props.departurePort}
              currentPrice={this.props.currentPrice}
              depositPrice={this.props.depositPrice}
            />
          </Row>
        </Container>
      </Container>
    ); } else {

    }
  }

}

export default Step5;
