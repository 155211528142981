import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import { Container, Row, Col, Button, ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import Intro from '../../Content/Intro';
import i18n from '../../i18n';
import moment from 'moment';

class Step2 extends Component {

  constructor(props) {
      super(props)
      this.state = {
        multipleDate: false,
        startDate: null,
        endDate: null,
        singleDate: null,
        focusedInput: null,
        blocked: null,
        badDatesPre: [],
        badDates: [],
      }
      if( this.state.maxStep < 2 ){
        this.state = {
          maxStep: 2,
        }
      }
    }

    componentDidMount() {
      let pagesURL = 'https://www.googleapis.com/calendar/v3/calendars/' + process.env.REACT_APP_CAL_ID + '/events/?key=' + process.env.REACT_APP_GCAL_KEY;
      fetch( pagesURL )
      .then( response => response.json() )
      .then( response => {
        this.setState({
          badDatesPre: response.items
        })
      })

    }

    checkForBlockedDates(start, end, dates) {
      const dateFormat = 'YYYY-MM-DD';
      const diff = moment(end).diff(start, 'days') + 1;

      for (let i = 0; i < diff; i++) {
        const checkDate = moment(start).add(i, 'd').format(dateFormat);

        if (dates[checkDate] && dates[checkDate].blocked) {
          return true;
        }
      }
    return false;
  }

  render() {
    let badDatesPreStart = this.state.badDatesPre.map(( date, i ) => {
        return (
          date.start.date
          ? moment(moment(date.start.date).format('YYYY-MM-DD'))
          : moment(moment(date.start.dateTime).format('YYYY-MM-DD'))
        )
    })

    let badDatesPreEnd = this.state.badDatesPre.map(( date, i ) => {
        return (
          date.end.date
          ? moment(moment(date.end.date).subtract(1, 'day').format('YYYY-MM-DD'))
          : moment(moment(date.end.dateTime).format('YYYY-MM-DD'))
        )
    })

    if (this.props.currentStep !== 2) {
      return null
    }

    const BAD_DATES_RETURNED_S = badDatesPreStart;
    const BAD_DATES_RETURNED_E = badDatesPreEnd;
    const BAD_DATES_DEFAULT = [ moment() ];
    const BAD_DATES_S = BAD_DATES_DEFAULT.concat(BAD_DATES_RETURNED_S);
    const BAD_DATES = BAD_DATES_S.concat(BAD_DATES_RETURNED_E);
    const isDayBlocked = day => BAD_DATES.filter(d => d.isSame(day, 'day')).length > 0;

    if(this.state.multipleDate === false){
      return (
        <Container>
          <Row>
            <h3 className="step">{i18n.t('step2.span')}</h3>
          </Row>
          <Intro
          title="Select Date"
          text="Please select your preferred date"
          removeLine
          />
          <Container style={{textAlign: 'center', marginTop: '-80px', }}>
            <Row className="justify-content-md-center">
              <Row className="justify-content-md-center" style={{width: '100%', margin: '0 auto'}}>
                <Col>
                  <ButtonToolbar>
                    <ToggleButtonGroup type="radio" name="options" defaultValue={1} style={{ margin: '0 auto' }}>
                      <ToggleButton onClick={() => {this.setState({ multipleDate: false, endDate: null })}} value={1}>Day trips</ToggleButton>
                      <ToggleButton onClick={() => {this.setState({ multipleDate: true })}} value={2}>Multiday trips</ToggleButton>
                    </ToggleButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Row>
              <Row className="justify-content-md-center" style={{width: '100%', margin: '0 auto'}}>
                <Col>
                  <SingleDatePicker
                    openDirection="down"
                    numberOfMonths={1}
                    date={this.state.startDate}
                    focused={this.state.focused}
                    onFocusChange={({ focused }) => this.setState({ focused })}
                    id="your_unique_date_id"
                    onDateChange={startDate => this.setState({ startDate })}
                    isDayBlocked={isDayBlocked}
                  />
                </Col>
              </Row>
            </Row>
            <Row className="justify-content-md-center">
              <Col>
                <Button onClick={() => { this.props.getDates(this.state.startDate, this.state.endDate) }}>{i18n.t('next.span')}</Button>
              </Col>
            </Row>
          </Container>
        </Container>
      );
    } else {
      return (
        <Container>
          <Row>
            <h3 className="step">{i18n.t('step2.span')}</h3>
          </Row>
          <Intro
          title="Select Dates"
          text="Please select your preferred dates"
          removeLine
          />
          <Container style={{textAlign: 'center', marginTop: '-80px', }}>
            <Row className="justify-content-md-center">
              <Row className="justify-content-md-center"  style={{width: '100%', margin: '0 auto'}}>
                <Col>
                  <ButtonToolbar>
                    <ToggleButtonGroup type="radio" name="options" defaultValue={1} style={{ margin: '0 auto' }}>
                      <ToggleButton onClick={() => {this.setState({ multipleDate: false, endDate: null })}} value={1}>Day trips</ToggleButton>
                      <ToggleButton onClick={() => {this.setState({ multipleDate: true })}} value={2}>Multiday trips</ToggleButton>
                    </ToggleButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Row>
              <Row className="justify-content-md-center" style={{width: '100%'}}>
                <Col>
                  <DateRangePicker
                    openDirection="down"
                    numberOfMonths={1}
                    startDate={this.state.startDate}
                    startDateId="your_unique_start_date_id"
                    endDate={this.state.endDate}
                    endDateId="your_unique_end_date_id"
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                    isDayBlocked={isDayBlocked}
                    showClearDates
                    reopenPickerOnClearDates
                  />
                </Col>
              </Row>
            </Row>
            <Row className="justify-content-md-center">
              <Col>
                <Button onClick={() => { this.props.getDates(this.state.startDate, this.state.endDate) }}>{i18n.t('next.span')}</Button>
              </Col>
            </Row>
          </Container>
        </Container>
      );
    }


  }
}

export default Step2;
