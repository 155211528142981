import i18next from 'i18next';

i18next
  .init({
    interpolation: {
      escapeValue: false,
    },
    lng: 'en', // 'en' | 'pt'
    resources: {
      en: {
        translation: {
          my_account: { span: 'My Account', },
          book_experience: { span: 'Book', },
          book: { span: 'Book', },
          book_online: { span: 'Book online', },
          more_details: { span: 'More details' },
          view_yacht: { span: 'view yacht' },
          your_message: { span: 'Your message' },
          send_your_message: { span: 'Send your message' },
          thanks_space:{ span: 'Thanks ' },
          thanks:{ span: 'Thanks' },
          go_to_checkout:{ span: 'Go to checkout'},
          next_section:{ span: 'Next section'},
          next:{ span: 'Next' },
          previous:{ span: 'Previous' },
          no_thanks:{ span: 'No Thanks' },
          send_love:{ span: 'Send me the love' },
          secured_information:{ span: 'Dont worry - your information will never be shared with anyone annoying.'},
          from: { span: 'from' },
          day: { span: 'day' },
          read_more: { span: 'read more', },
          enquire: { span: 'enquire', },
          send_an_email: { span: 'Send an email', },
          send_you_message: { span: 'Send your message', },
          copywrite: { span: 'Copyright 2019 Sunseekers Portugal. All Rights Reserved ', },
          designed: { span: 'Web Design by:', },
          select_yacht: { span: 'Select Yacht', },
          select_date: { span: 'Select date', },
          select_route: { span: 'Select route', },
          extras: { span: 'extras', },
          payment: { span: 'payment', },
          confirmation: { span: 'Confirmation', },
          your_name: { span: 'Your name', },
          your_phone: { span: 'Your phone number', },
          your_email: { span: 'Your email address', },
          your_address: { span: 'Your address', },
          your_address1: { span: 'Address: line 1', },
          your_address2: { span: 'Address: line 2', },
          your_city: { span: 'City', },
          your_state: { span: 'State', },
          your_postcode: { span: 'Post code', },
          your_partner: { span: 'Are you a partner?', },
          your_partner_detail: { span: 'Enter your code here', },
          your_cardnumber: { span: 'Card Number', },
          submit: { span: 'submit', },
          step1: { span: 'step 1', },
          step2: { span: 'step 2', },
          step3: { span: 'step 3', },
          step4: { span: 'step 4', },
          step5: { span: 'step 5', },
          step6: { span: 'step 6', },
          luxury_experiences: { span: 'Luxury Experiences:', },
          day_trips: { span: 'Day Trips:', },
          not_the_route: { span: 'Not the route you’re looking for? No problem. Just enquire and we will arrange a bespoke route.', },
          overview: { span: 'overview', },
          gallery: { span: 'gallery', },
          brochure: { span: 'brochure', },
          route: { span: 'route', },
          pay: { span: 'pay', },
          back: { span: 'back', },
          menu_options: { span: 'Menu options', },
          menu: { span: 'Menu', },
          per_person: { span: 'per person', },
          chef_selection: { span: 'Chef selection', },
          allergies: { span: 'Anything we should note (allergies, dietery requirements)?', },
          add_another_route: { span: 'Add Another Route?', },
          dining_on_board: { span: 'Dining On Board', },
          drinks: { span: 'Drinks', },
          provisioning_services: { span: 'Provisioning Services', },
          sleep_aboad: { span: 'Sleep Aboad', },
          special_requests: { span: 'Special Requests', },
          towels_and_linen: { span: 'Towels & Linen', },
          watersports: { span: 'Watersports', },
          guests: { span: 'Guests', },
          itineraries: { span: 'Itineraries', },
          contact_us: { span: 'Contact us', },
        },
      },
      pt: {
        translation: {
          my_account: { span: 'Minha conta', },
          book_experience: { span: 'Reserve uma experiência', },
          book_online: { span: 'reservar online', },
          more_details: { span: 'Mais detalhes' },
          from: { span: 'do' },
          day: { span: 'dia' },
          read_more: { span: 'consulte Mais informação', },
          enquire: { span: 'investigar', },
          send_an_email: { span: 'Enviar um email', },
          send_you_message: { span: 'Envie sua mensagem', },
          copywrite: { span: 'Copyright 2019 Sunseekers Portugal. Todos os direitos reservados', },
          designed: { span: 'Web Design por:', },
          select_yacht: { span: 'Selecione Iate', },
          select_date: { span: 'Selecione a data', },
          select_route: { span: 'selecionar rota', },
          extras: { span: 'o acréscimo', },
          payment: { span: 'os pagamento', },
          confirmation: { span: 'Confirmação', },
          your_name: { span: 'Seu nome', },
          your_phone: { span: 'Seu número de telefone', },
          your_email: { span: 'Seu endereço de email', },
          your_address: { span: 'Seu endereço', },
          your_address1: { span: 'Endereço Linha 1', },
          your_address2: { span: 'Endereço Linha 2', },
          your_city: { span: 'Cidade', },
          your_state: { span: 'Município', },
          your_postcode: { span: 'CEP', },
          your_partner: { span: 'Você é um parceiro?', },
          your_partner_detail: { span: 'Se você é nosso parceiro, por favor digite seu código aqui', },
          your_cardnumber: { span: 'número do cartão', },
          submit: { span: 'aceder', },
          step1: { span: 'degrau 1', },
          step2: { span: 'degrau 2', },
          step3: { span: 'degrau 3', },
          step4: { span: 'degrau 4', },
          step5: { span: 'degrau 5', },
          step6: { span: 'degrau 6', },
          luxury_experiences: { span: 'Experiências de luxo:', },
          day_trips: { span: 'Dias de viagem:', },
          not_the_route: { span: 'Não é o caminho que você está procurando? Sem problemas. Basta perguntar e vamos organizar uma rota sob medida.', },
          overview: { span: 'visão global', },
          gallery: { span: 'galeria de fotos', },
          brochure: { span: 'folheto', },
          route: { span: 'via', },
          pay: { span: 'pay', },

        },
      },
    },
  })

export default i18next
