import React, { Component } from 'react';
import HomeHero from '../Components/PageParts/HomeHero';
import OurYachts from '../Components/Content/OurYachts';
import YachtCharter from '../Components/Content/YachtCharter';
import TheExperiance from '../Components/Content/TheExperiences';

class HomePage extends Component {
  render() {
    return (
      <>
        <HomeHero />
        <OurYachts />
        <YachtCharter />
        <TheExperiance />
      </>
    );
  }
}

export default HomePage;
