import React, { Component } from 'react';
import { Container, Row, Form, Card, Accordion, Button } from 'react-bootstrap';
import i18n from '../../../i18n';

class TowelsAndLinen extends Component {

  render() {
    return (
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="8">
            Towels & Linen
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="8">
          <Card.Body>
            <Container>
              <Row style={{paddingTop: '0px'}}>
                <Form.Group controlId="formBasicChecbox">
                  <Form.Check type="checkbox" label="Please include towels & linen" />
                </Form.Group>
              </Row>
              <Row style={{ paddingBottom: '6px' }}>
                <Button onClick={this.props.next}>{i18n.t('go_to_checkout.span')}</Button>
              </Row>
            </Container>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }

}

export default TowelsAndLinen;
