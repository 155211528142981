import React, { Component } from 'react';
import { Container, Row, Col, Form, Card, Accordion, Button } from 'react-bootstrap';
import i18n from '../../../i18n';

class Guests extends Component {

  constructor() {
    super()
    this.state = {
      numberOfGuests: 1,
    }

  }

  handleChange() {
    if (this.refs.guests) {
      this.setState({
        numberOfGuests: this.refs.guests.value,
      })
      this.props.handleGuestChange(this.refs.guests.value);
    }
  }


  render() {
    return (
      <Card>
        <Card.Header>
          <Accordion.Toggle  as={Button} variant="link" eventKey="guests">
            {i18n.t('guests.span')}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="guests">
          <Card.Body>
            <Container>
              <Row style={{paddingTop: '0px'}}>
                <Col style={{ paddingLeft: '0px' }} >
                  <Form.Label>{this.state.numberOfGuests} Number of guests </Form.Label>
                  <Form.Control as="select" ref="guests" value={this.state.numberOfGuests} onChange={() => this.handleChange()} >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row style={{ paddingBottom: '6px' }}>
                <Accordion.Toggle as={Button} variant="primary" eventKey="0">{i18n.t('next_section.span')}</Accordion.Toggle>
              </Row>
            </Container>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }

}

export default Guests;
