import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import i18n from '../i18n';

const preButtons = {
  color: '#ffffff',
  fontFamily: "futura-pt, sans-serif",
  fontSize: '16px',
  fontWeight: '500',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  marginBottom: '0px',
  marginRight: '20px',
  width: '600px',
}

class SubPageHeader extends Component {
  render() {
    return (
      <Container className="subHeader" fluid>
        <Container xs={12}>
          <Row className="justify-content-md-center" style={{paddingTop: '10px', paddingBottom: '10px'}}>
            <Col xs={12} md={6} lg={6} className="horizontalAlign">
              <p style={ preButtons }>{i18n.t('luxury_experiences.span')}</p>
              <NavLink to='/contact' className="btn a-reverseWhite">{i18n.t('enquire.span')}</NavLink>
            </Col>

          </Row>
        </Container>
      </Container>
    );
  }

}

export default SubPageHeader;
