import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';

class MenuItem extends Component {

  hideMenu() {
    $('.navbar-collapse').addClass('hide')
    $('.navbar-collapse').removeClass('show')
    $('.navbar-toggler').removeClass('collapsed')
  }

  render() {
    return (
      <>
        <Nav.Item  className="menuItem">
          <NavLink onClick={ () => this.hideMenu() } to={'/' + this.props.to.pathname} className="ss-menuLink">{this.props.title.split(" ")[0]}<strong> {this.props.title.split(" ")[1]}</strong></NavLink>
        </Nav.Item>
      </>
    );
  }

}

export default MenuItem;
