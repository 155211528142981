import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import axios from "axios";

class Testimonials extends Component {

  state = {
    testimonials: [],
    randomTestimonial: 'empty',
    isLoading: true,
    errors: null
  };

  getConfig() {
  axios
    .get( process.env.REACT_APP_ACF_API + "options/acf-testimonials-general/")
    .then(response => {
      this.setState({
        testimonials: response.data.acf,
      });
      this.componentAfter()
    })
    .catch(error => this.setState({ error, isLoading: false }));
}

  componentDidMount() {
    this.getConfig();
  }

  componentAfter(){
    if(this.state.testimonials.testimonails) {
      var aim = this.state.testimonials.testimonails
      this.setState({
        randomTestimonial: aim[Math.floor(Math.random()*aim.length)]
      });
    }
  }

  render() {


    return (
        <Card.Text>{this.state.randomTestimonial.testimonial_text} {this.state.randomTestimonial.testimonial_name ? this.state.randomTestimonial.testimonial_name : null}</Card.Text>
    )
  }
}

export default Testimonials;
