import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import PhoneNumber from '../Calls/PhoneNumber'
import Email from '../Calls/Email'
import Social from '../Calls/Social'
import i18n from '../i18n';


import logoFile from '../../images/logo.png';


const logo = {
  src: logoFile,
  alt: 'Sunseeksers',
};

const logoStyle = {
  marginTop: '-26px',
  margin: '0 auto',
  display: 'block',
  width: '240px',
}

class Header extends Component {

  nextPath(path) {
    this.props.history.push(path);
  }

  render() {
    return (
      <Container>
        <Row className="preHeader">
          <Col lg={2} className="horizontalAlign d-none d-lg-flex" >
            <Social
              dark='true'
              header='true'
            />
          </Col>
          <Col xs={12} md={4} lg={2} className="header_phone" style={{paddingRight: '5px', paddingLeft: '5px'}} >
            <PhoneNumber
              dark
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
              <NavLink to="/">
                <img style={ logoStyle } src={ logo.src } alt={ logo.alt } />
              </NavLink>
          </Col>
          <Col md={4} lg={2} className="rightAlign d-none d-md-flex" >
            <Email
              dark
            />
          </Col>
          <Col lg={2} className="horizontalAlign d-none d-lg-flex" >
            <NavLink to='/book' className="btn a-primary">{i18n.t('book_experience.span')}</NavLink>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Header;
