import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel'
import PropTypes from 'prop-types';

class ImageSlider extends Component {

  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      imageGal: [],
      index: 0,
      direction: null,
    };
  }

  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  }

  componentDidMount() {
    var theApi = process.env.REACT_APP_ACF_API + 'yachts/' + this.props.id + '/images';
    var theApiString = theApi
    let imageGalURL = theApiString;
    fetch(imageGalURL)
      .then( response => response.json() )
      .then( response => {
        this.setState({
          imageGal: response.images.map((response) => ({
            image: response.image
          }))
        })
      })
    }

  render() {
    const { index, direction } = this.state;
    let imageGal = this.state.imageGal.map(( item, index ) => {
      if(item.image !== null ){
        return (
          <div>
            <Carousel.Item className="imageStyle" style={{ minHeight: '500px', maxHeight: '500px' }}>
              <img key={ index }
                className="img-fluid"
                src={item.image}
                alt="First slide"
                style={{ objectPosition: 'center', objectFit: 'cover', minWidth: '100%' }}
              />
            </Carousel.Item>
          </div>
        )
      } else {
        return (
          null
        )
      }

    })

    return (
      <Carousel
        id="gallery"
        fluid={true}
        className="heroStyle"
        activeIndex={index}
        direction={direction}
        onSelect={this.handleSelect}
        >
          {imageGal}
      </Carousel>
    );
  }

}

ImageSlider.propTypes = {
  id:  PropTypes.string,
};

export default ImageSlider;
