import React, { Component } from 'react';
import { Container, Row, Form, Card, Accordion, Button } from 'react-bootstrap';
import i18n from '../../../i18n';

class Watersports extends Component {

  render() {
    return (
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="2">
            {i18n.t('watersports.span')}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <Container>
              <Row style={{paddingTop: '0px'}}>
                <Form.Group controlId="formBasicChecbox">
                  <Form.Check type="checkbox" label="paddle boards" />
                </Form.Group>
              </Row>
              <Row style={{ paddingBottom: '6px' }}>
                <Accordion.Toggle as={Button} variant="primary" eventKey="3">{i18n.t('next_section.span')}</Accordion.Toggle>
              </Row>
            </Container>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }

}

export default Watersports;
