import React, { Component } from 'react';
import BookExperienceForm from '../Components/Forms/BookExpForm';


class BookExperience extends Component {
  render() {
    return (
      <>
        <BookExperienceForm />
      </>
    );
  }
}

export default BookExperience;
