import React, { Component } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types';


const hrShift = {
  transform: 'translateY(390px)',
  height: '223px',
}

class LuxurayExperiance extends Component {

  render() {
    var str = this.props.title;
    var lastIndex = str.lastIndexOf(" ");
    var firstPart = str.substring(0, lastIndex);
    return (
    <Container className="singleLux" style={{ marginBottom: '90px', }} >
      <Row className="experiances">
        <Col lg={6} md={12} xs={12} className="experiances--left">
          <Row>
            <Col lg={3} md={12} xs={12} className="buttonHolder">
              {this.props.iconOne ?
                <Button onClick={()=>this.props.downloadLink} variant="mini"><i style={{ fontWeight: '200', fontStyle: 'normal', fontFamily: 'FontAwesome', }} className={"fas " + this.props.iconOne}></i></Button>
              :
                null
              }
              {this.props.iconTwo ?
                <Button onClick={()=>this.props.downloadLink} variant="mini"><i style={{ fontWeight: '200', fontStyle: 'normal', fontFamily: 'FontAwesome', }} className={"fas " + this.props.iconTwo}></i></Button>
              :
                null
              }
            </Col>
            <Col lg={9} md={12} xs={12}>

              <h2 className="expTitle">{ firstPart }<strong> {this.props.title.split(" ").pop()}</strong></h2>
              <p dangerouslySetInnerHTML={{__html: this.props.text}}></p>
              <br/>
              <p style={{ fontSize: '10px', letterSpacing: '0.45px' }}>{ !this.props.url ? "Not Currently Available. We are Sorry" : null }</p>
              <NavLink to={this.props.url ? this.props.url : '/contact'} className="btn a-primary">{this.props.buttonText ? this.props.buttonText : "enquire" }</NavLink>
            </Col>
          </Row>
        </Col>
        <Col lg={6} md={12} xs={12} className="experiances--right">
          <Card>
            <Card.Img src={this.props.imageSrc} alt={this.props.imageAlt} style={{ objectFit: 'cover', minHeight: '600px', }} />
            <Card.ImgOverlay className="overlayHover">
            <hr className="horixontal d-none d-lg-flex" style={ hrShift } />
            </Card.ImgOverlay>
          </Card>
        </Col>
      </Row>
    </Container>
    );
  }

}

LuxurayExperiance.propTypes = {
  downloadLink:  PropTypes.string,
  text:  PropTypes.string,
  title: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
};

export default LuxurayExperiance;
