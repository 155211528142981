import React, { Component } from 'react';
import SubMenuHeader from '../../Components/PageParts/SubMenuHeader';
import Slider from '../../Components/PageParts/Slider';
import sliderImageFile from '../../images/tmp/ourYachtsHeader.png';
import Intro from '../../Components/Content/Intro';
import DayRoutes from '../../Components/Content/DayRoutes';
import ImageSlider from '../../Components/PageParts/ImageSlider';
import Itineries from '../../Components/Content/Itineries';
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import axios from "axios";


const hrShift = {
  transform: 'translateY(331px)',
  height: '123px',
}

const sliderImage = {
  src: sliderImageFile,
  alt: "this is an image",
}

class Yacht extends Component {

  constructor(){
     super();
      this.state = {
        pages: []
      };
  }

  getConfig() {
    var theApi = process.env.REACT_APP_GEN_API + 'yachts?slug='
    var theSlug = window.location.pathname.slice(12)
    var theCall = theApi + theSlug
    let pagesURL = theCall;
    console.log(pagesURL);
    axios
      .get(pagesURL)
      .then(response => {
        this.setState({
          pages: response.data
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    this.getConfig();
  }

  render() {

    let pages = this.state.pages.map(( page, index ) => {
        return (
          <div keys={page.id} >
            <SubMenuHeader
              gallery={page.acf.image_slider}
              brochure={page.acf.brochure_link}
              route={this.props.routeLink}
              bookable={page.acf.yacht_bookable}
            />
            {page.better_featured_image ?
            <Slider
              imageSrc={page.better_featured_image.source_url}
              imageAlt={sliderImage.alt}
              title={page.title.rendered}
              singleYacht
            />
            : null}

            <Intro
              title="yacht Overview"
              text={page.acf.yachtoverview_intro}
              meters={page.acf.yachtoverview_meters}
              guests={page.acf.yachtoverview_guests}
              knots={page.acf.yachtoverview_knots}
              crew={page.acf.yachtoverview_crew}
              price={page.acf.yacht_price}
            />
            { page.acf.image_slider === true ?
              <ImageSlider
                key={page.id}
                id={page.id}
              />
              : null
            }

            {page.acf.focusinfo.map(( page, index ) => {
              return (
                <Container key={Math.random()} className="singleLux" style={{ marginBottom: '90px', }} >
                  <Row className="experiances">
                    <Col lg={6} md={12} xs={12} className="experiances--left">
                      <Row>
                        <Col lg={9} md={12} xs={12}>
                          <h2 className="expTitle" style={{textTransform: 'uppercase'}}>{ page.title }</h2>
                          <p dangerouslySetInnerHTML={{__html: page.text}}></p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={12} xs={12} className="experiances--right">
                      <Card>
                        <Card.Img src={page.image} alt={page.title} style={{ objectFit: 'cover', minHeight: '400px', maxHeight: '400px', }} />
                        <Card.ImgOverlay className="overlayHover">
                        <hr className="horixontal d-none d-lg-flex" style={ hrShift } />
                        </Card.ImgOverlay>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              )
              })
            }

            {page.acf.info_accordion ?
              <Container>
                <Accordion className="extras" >
                  {page.acf.info_accordion.map(( page, index ) => {
                      return (
                        <Card>
                          <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                              {page.title}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={index}>
                            <Card.Body>
                              <Container>
                                <div dangerouslySetInnerHTML={{ __html: page.content }}/>
                              </Container>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>

                      )
                    })
                  }
                </Accordion>
              </Container>
            :
              null
            }
            <Itineries/>
            {page.content.rendered ?
              <Container>
                <Row>
                  <Col>
                    <div dangerouslySetInnerHTML={{ __html: page.content.rendered }}/>
                  </Col>
                </Row>
              </Container>
            : null}
            {page.acf.brochure_link ?
              <Intro
                id="brochure"
                title="Download Brochure"
                text={page.acf.brochure_intro}
                buttonLink={page.acf.brochure_link}
                buttonTitle="Download"
                removeLine
              />
            : null }
            {this.props.routeLink ?
              <DayRoutes
                id="thisId"
              />
            : null }
          </div>
        )
    })

    return (
      <>
        {pages}
      </>
    );
  }

}

export default Yacht;
