import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import Intro from '../../Content/Intro';
import i18n from '../../i18n';

class Step6 extends Component {

  render() {
    if (this.props.currentStep !== 6) { // Prop: The current step
      return null
    }
    return (

      <Container>
        <Row>
          <h3 className="step">{i18n.t('step6.span')}</h3>
        </Row>
        <Intro
        title="Thank you"
        text="Thats all done, we look forward to seeing you soon!"
        removeLine
        />

      </Container>
    );
  }

}

export default Step6;
