import React, { Component } from 'react';
import { Container, Col, Row } from 'react-bootstrap'
import as from '../../images/as-logo.png';
import i18n from '../i18n';

const center = {
  textAlign: 'center',
}

const footer = {
  color: '#929292',
  fontFamily: "Source Sans Pro",
  fontSize: '14px',
  fontWeight: '400',
}

const sub_footer = {
  color: '#929292',
  fontFamily: "Source Sans Pro",
  fontSize: '14px',
  fontWeight: '400',
  cursor: 'pointer',
}

const asLogo = {
  src: as,
  alt: 'Authentic Style',
};

class SubFooter extends Component {
  render() {
    return (
      <Container className="justify-content-center">
        <Row>
          <Col style={ center }>
            <p style={ footer }>{i18n.t('copywrite.span')} <a href="/index.html" style={ footer }>privacy policey</a></p>
          </Col>
        </Row>
        <Row style={{ marginTop: '-10px', paddingTop: '0px' }}>
          <Col style={ center }>
            <p onClick={() => { window.open('https://www.authenticstyle.co.uk')}} style={ sub_footer }>{i18n.t('designed.span')} <img src={ asLogo.src } alt={ asLogo.alt } /></p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default SubFooter;
