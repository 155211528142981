import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './style.scss';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import StartAtTop from './Components/StartAtTop';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import Header from './Components/PageParts/Header';
import Menu from './Components/PageParts/Menu';
import PreFooter from './Components/PageParts/PreFooter';
import SubFooter from './Components/PageParts/SubFooter';
import HomePage from './Pages/Home';
import OurYachts from './Pages/OurYachts';
import GenericTemplate from './Pages/GenericPage';
import InternationalCharter from './Pages/YachtCharter';
import YachtCharter from './Pages/YachtCharter';
import LuxuryExperiences from './Pages/LuxuryExperiences';
import LatestNews from './Pages/LatestNews';
import ContactUs from './Pages/ContactUs';
import Yacht from './Pages/Yachts/Yacht';
import BookExperience from './Pages/BookExperience';
import NoMatchPage from './Pages/NoMatch';

export function IndexPage() {
  return <div className="pg"><HomePage /></div>;
}

function GenericPage() {
  return <div className="pg"><GenericTemplate /></div>;
}

function OurYachtsPage() {
  return <div className="pg"><OurYachts /></div>;
}

function YachtCharterPage() {
  return <div className="pg"><YachtCharter /></div>;
}

function LuxuryExperiencePage() {
  return <div className="pg"><LuxuryExperiences /></div>;
}

function InternationalCharterPage() {
  return <div className="pg"><InternationalCharter /></div>;
}

function LatestNewsPage() {
  return <div className="pg"><LatestNews /></div>;
}

function ContactUsPage() {
  return <div className="pg"><ContactUs /></div>;
}

function YachtPage() {
  return <div className="pg"><Yacht /></div>;
}

function BookingPage() {
  return <div className="pg"><BookExperience /></div>;
}

function AdminForward() {
  return (
    window.location = process.env.REACT_APP_WP_ADMIN
  );
}

function NoMatch() {
  return <div className="pg"><NoMatchPage /></div>;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlushed: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.dealLoaded && nextProps.dealLoaded) {
      this.setState({
        isFlushed: false,
      });
    }

    if (!this.state.isFlushed && nextProps.location.state === 'flushDeal') {
      this.setState({
        isFlushed: true,
      }, () => this.props.loadDeals());
    }
  }

  authenticate() {
    return new Promise(resolve => setTimeout(resolve, 2000));
  }

  componentDidMount() {
    this.authenticate().then(() => {
      const ele = document.getElementById('ipl-progress-indicator');
      if (ele) {
        ele.classList.add('available');
        setTimeout(() => {
          ele.outerHTML = '';
        }, 2000);
      }
    });
  }

  render() {
    return (
      <Router>
        <StartAtTop>
          <div className="App">
            <Header />
            <Menu />
            <Route
              render={({ location }) => {
                return (
                  <TransitionGroup component={null}>
                   <CSSTransition
                     timeout={300}
                     classNames="page"
                     key={location.key}
                   >
                        <Switch location={location}>
                          <Route path="/about/:slug" component={GenericPage} />
                          <Route path="/our-yachts/:slug" component={YachtPage} />
                          <Route path="/about" component={IndexPage} />
                          <Route path="/our-yachts" component={OurYachtsPage} />
                          <Route path="/yacht-charter" component={YachtCharterPage} />
                          <Route path="/luxury-experiences" component={LuxuryExperiencePage} />
                          <Route path="/international-charters" component={InternationalCharterPage} />
                          <Route path="/latest-news" component={LatestNewsPage} />
                          <Route path="/contact" component={ContactUsPage} />
                          <Route path="/yacht" component={YachtPage} />
                          <Route path="/book" component={BookingPage} />
                          <Route path="/home" component={IndexPage} />
                          <Route path="/wp-admin" component={AdminForward} />
                          <Route path="/" component={IndexPage} exact />
                          <Route component={NoMatch} />
                        </Switch>
                      </CSSTransition>
                 </TransitionGroup>
               );
             }} />
             <PreFooter />
             <SubFooter />
           </div>
        </StartAtTop>
      </Router>
    );
  }
}

export default App;
