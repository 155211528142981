import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import placeholderImg from '../../images/tmp/charterBG.png'

const placeholder = {
  src: placeholderImg,
};

const imageStyle = {
  borderRadius: '0px',
  maxHeight: '532px',
  opacity: '0',
}

class Slider extends Component {
  render() {
    return (
      <Card fluid="true" style={{ height:'450px', width: '100%', backgroundColor: 'rgba(0,0,0,0.1)', border: '0px' }} className="slider">
        <Card.Img src={placeholder.src} border="0px" alt="loading" style={ imageStyle } />
        <Card.ImgOverlay className="sliderOverlay">
          <div className="sliderOverlay_inner">
          </div>
        </Card.ImgOverlay>
      </Card>
    );
  }

}

export default Slider;
