import React, { Component } from 'react';
import {
  Container, Row, Col, Card, Button,
} from 'react-bootstrap';
import axios from 'axios';

import Intro from '../Components/Content/Intro';
import BlogPostPreview from '../Components/Content/Singles/BlogPostPreview';

const cardOverlay = {
  textAlign: 'center',
  bottom: '30px',
};

class YachtCharterPage extends Component {
  constructor() {
    super();
    this.state = {
      pages: [],
      loaded: false,
    };
  }

  componentWillMount() {
    this.getConfig();
  }

  getConfig() {
    const theCall = `${process.env.REACT_APP_ACF_API}pages/10/`;
    const pagesURL = theCall;
    axios
      .get(pagesURL)
      .then(async(response) => {
        this.setState({
          pages: response.data.acf.int_chart_dest,
          loaded: true,
        });
      });
  }

  render() {
    const theResult = this.state.pages;
    const prePages = (
      <>
      <Col xs={12} md={6}>
        <div className="previewHolder">
          <div className="temp"></div>
        </div>
      </Col>
      <Col xs={12} md={6}>
        <div className="previewHolder">
          <div className="temp"></div>
        </div>
      </Col>
      <Col xs={12} md={6}>
        <div className="previewHolder">
          <div className="temp"></div>
        </div>
      </Col>
      </>
      );
    const pages = theResult.map(page => (
      <Col xs={12} md={6} key={page.title}>
        <div className="previewHolder">
          <BlogPostPreview
            title={page.title}
            text={page.text}
            alt={page.title}
            bgImage={page.image}
          />
        </div>
      </Col>
    ));

    const { bgImage } = this.props;
    const { alt } = this.props;

    return (
      <>
        <Intro
          title="INTERNATIONAL CHARTERS"
          text="If you are interested in chartering in some truly break-taking locations around the world, then visit our partners, <a style='color: #a99b6c;' href='https://www.sunseekercharters.com'>Sunseeker Charters</a> to find out more. Below you can get a gimpse of some of the locations on offer."
        />

        <Container>
          <Row>

            {this.state.loaded ? pages : prePages }
            <Col xs={12} md="6">
              <div className="previewHolder">
                <Container>
                  <Card className="goldOne feat-yachts even-yacht text-white">
                    <Card.Img src={bgImage} alt={alt} />
                    <Card.ImgOverlay style={cardOverlay}>
                      <div className="goldOverlay">
                        <h3>BOOK AN INTERNATIONAL CHARTER</h3>
                        <h4>
                        A Sunseeker Charter is the holiday you’ve always dreamt of.
                        It’s the warm glow from relinquishing control.
                        It’s waking up in beautiful still waters in time for a swim,
                        And going to sleep with the sound of the waves lapping.
                        </h4>
                        <Button size="l" variant="reverseWhite" onClick={ () => window.open('https://sunseekercharters.com') } >Visit sunseekers charters</Button>
                      </div>
                    </Card.ImgOverlay>
                  </Card>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default YachtCharterPage;
